import { AnyAction } from '@reduxjs/toolkit';
import { DashboardRepository } from '../../domain/dashboard/DashboardRepository';
import * as actionTypes from './dashboardActionTypes';
import * as generalActionTypes from '../generalActionTypes';

type DashboardRepositoryState = Omit<
  DashboardRepository,
  'getDashboardCases' | 'loadMoreDashboardCase'
>;
const INITIAL_STATE: DashboardRepositoryState = {
  dashboardCases: [],
  totalDashboardItems: 0,
  currentDashboardPage: 0,
  nextDashboardPage: 0,
  loadMore: false,
  hasMore: true,
  isLoading: false,
  isUpdating: false,
};

const dashboardReducer = (state: DashboardRepositoryState = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case actionTypes.GET_DASHBOARD_CASES:
      return { ...state, isLoading: true };
    case actionTypes.GET_DASHBOARD_CASES_SUCCESS:
      if (action.dashboardCases.length > 0) {
        return {
          ...state,
          dashboardCases: [...state.dashboardCases, ...action.dashboardCases],
          currentDashboardPage: action.page,
          nextDashboardPage: action.page + 1,
          totalDashboardItems: state.dashboardCases.length + action.dashboardCases.length,
          isLoading: false,
          loadMore: false,
        };
      }
      if (action.dashboardCaseslength === 0) {
        return { ...state, hasMore: false, isLoading: false };
      }
      return { ...state, isLoading: false, hasMore: false };
    case actionTypes.GET_DASHBOARD_CASES_FAILD:
      return { ...state, isLoading: false };
    case actionTypes.LOAD_MORE:
      return { ...state, loadMore: action.value };
    case actionTypes.CREATE_DASHBOARD_CASE:
      return {
        ...state,
        dashboardCases: [
          ...state.dashboardCases,
          {
            id: action.caseId,
            clientId: null,
            clientName: null,
            patientName: null,
            status: 0,
            labWorks: [],
          },
        ],
      };
    case actionTypes.UPDATE_DASHBOARD_CASE:
      if (action.case != null && action.case !== undefined) {
        return {
          ...state,
          dashboardCases: state.dashboardCases.map((caseItem) =>
            caseItem.id === action.case.id
              ? {
                  ...caseItem,
                  clientId: action.case.clientId,
                  clientName: action.case.clientName,
                  patientName: action.case.patientName,
                  status: action.case.status,
                  labWorks: action.case.labWorks,
                }
              : caseItem,
          ),
        };
      }
      return state;
    case actionTypes.DELETE_DASHBOARD_CASE:
      return {
        ...state,
        dashboardCases: state.dashboardCases.filter((item) => item.id !== action.caseId),
      };
    case generalActionTypes.RESET_LABOR_DATA:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default dashboardReducer;
export type { DashboardRepositoryState };
