import { CaseRepository } from '../../domain/cases/CaseRepository';

type ActionCasesRepository = Pick<CaseRepository, 'deleteCases' | 'closeReopenCase'>;

const deleteCasesUseCase = (repo: ActionCasesRepository, deletedCases: Array<number>) => {
  return repo.deleteCases(deletedCases);
};

const closeReopenUseCase = (
  repo: ActionCasesRepository,
  caseId: number,
  stackOfChanges: Array<string | number>[],
) => {
  return repo.closeReopenCase(caseId, stackOfChanges);
};

export { deleteCasesUseCase, closeReopenUseCase };
export type { ActionCasesRepository };
