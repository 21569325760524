import React, { FC } from 'react';
import styled from 'styled-components';

import { Text, TextType } from '../components/dsm/Text';
import Avatar from '../components/generic/Avatar';

import { DashboardLabWorkUiModel } from '../ui-model/DashboardUiModel';
import { ReactComponent as Flag } from '../../icons/flag.svg';

export type LabWorkCardComponent = {
  labWork: DashboardLabWorkUiModel;
  time: { time: string | undefined; expire: boolean } | undefined;
  leftEdge: boolean | undefined;
  rightEdge: boolean | undefined;
  className: string;
};

export type LabWorkCardComponentEmpty = {
  labWork: null;
  time: null;
  leftEdge: false;
  rightEdge: true;
  className: string;
};

export type LabWorkCardComponentProps = LabWorkCardComponent | LabWorkCardComponentEmpty;

export const LabWorkCardComponent: FC<LabWorkCardComponentProps> = ({
  labWork: labwork,
  time,
  leftEdge,
  rightEdge,
  className,
}) => {
  return (
    <LabWorkCardContainer>
      <FirstRowContainer>
        <NameText
          className={labwork === null ? className : ''}
          type={TextType.CAPTION_BOLD}
          ellipsis={true}
        >
          {labwork !== null && `${labwork?.labWorkName}, ${labwork?.labWorkSubcategory}`}
        </NameText>
        {time?.time !== undefined && (
          <DateContainer>
            <FlagIcon className={time?.expire ? 'Expire' : ''} />
            <DateText type={TextType.ROBOTO_400_10_14} className={time?.expire ? 'Expire' : ''}>
              {time?.time}
            </DateText>
          </DateContainer>
        )}
      </FirstRowContainer>
      <SecondRowContainer>
        {leftEdge && <LeftEdgeConatiner className={labwork?.previousPhaseStatus} />}
        <CurrentPhaseContainer
          className={`${labwork?.currentlyPhaseStatus}  ${labwork === null ? className : ''}`}
        >
          {labwork !== null && (
            <Avatar
              fullName={labwork?.currentlyAvatar}
              size={{ outer: 40, inner: 38 }}
              textType={TextType.BODY_BOLD}
              avatarStyle={{ background: labwork?.currentlyAvatarColor }}
            />
          )}

          <Text type={TextType.CAPTION_BOLD}>{labwork?.currentlyPhaseName}</Text>
        </CurrentPhaseContainer>
        {rightEdge && <RigthEdgeConatiner className={labwork === null ? className : ''} />}
      </SecondRowContainer>
    </LabWorkCardContainer>
  );
};

const BaseContainer = styled.div`
  display: flex;
  align-self: stretch;
  flex-grow: 0;
`;

const LabWorkCardContainer = styled(BaseContainer)`
  flex-direction: column;
  gap: 4px;
`;

const FirstRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex: none;
  gap: 4px;
`;

const SecondRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
  justify-content: space-between;
  align-self: stretch;
  flex-grow: 1;
`;

const NameText = styled(Text)`
  line-height: 14px;
  &.emptyDashboardField {
    min-height: 14px;
    flex-grow: 1;
  }
  &.emptyDashboardPlaceholderField {
    min-height: 14px;
    flex-grow: 1;
  }
`;

const DateContainer = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
  min-width: 50px;
  justify-content: flex-end;
`;

const DateText = styled(Text)`
  &.Expire {
    color: var(--dts_red);
  }
`;

const FlagIcon = styled(Flag)`
  width: 14px;
  height: 14px;
  flex-shrink: 1;
  fill: var(--dts_black);
  &.Expire {
    fill: var(--dts_red);
  }
`;

const CurrentPhaseContainer = styled.div`
  border-radius: 3px;
  padding: 10px 12px 10px 12px;
  background: var(--dts_light_grey);
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
  &.Started {
    background: var(--phase-started-background-color);
  }
  &.Error {
    background: var(--phase-error-background-color);
  }
  &.Finished {
    background: var(--phase-finished-background-color);
  }
  &.emptyDashboardField {
    background: var(--dts_light_grey);
    min-height: 40px;
  }
  &.emptyDashboardPlaceholderField {
    background: var(--dts_withe_background);
    min-height: 40px;
  }
`;

const BaseEdgeContainer = styled.div`
  width: 8px;
  height: 100%;
  background: var(--dts_light_grey);
`;

const LeftEdgeConatiner = styled(BaseEdgeContainer)`
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  &.Started {
    background: var(--phase-started-background-color);
  }
  &.Error {
    background: var(--phase-error-background-color);
  }
  &.Finished {
    background: var(--phase-finished-background-color);
  }
`;

const RigthEdgeConatiner = styled(BaseEdgeContainer)`
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  &.emptyDashboardField {
    background: var(--dts_light_grey);
  }
  &.emptyDashboardPlaceholderField {
    background: var(--dts_withe_background);
  }
`;
