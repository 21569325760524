import { checkErrorStatus } from '../ErrorHandling';
import * as actionTypes from './dashboardActionTypes';
import { getDashboardCases } from './dashboardService';

const getDashboardCasesAction = (pageNumber: number, itemNumber: number) => (dispatch: any) => {
  dispatch({ type: actionTypes.GET_DASHBOARD_CASES });
  return getDashboardCases(pageNumber, itemNumber).then((dashboardCases) => {
    if (checkErrorStatus(dashboardCases.code, dispatch)) {
      dispatch({
        type: actionTypes.GET_DASHBOARD_CASES_SUCCESS,
        dashboardCases: dashboardCases.dashboardCases,
        page: pageNumber,
      });
    } else {
      dispatch({ type: actionTypes.GET_DASHBOARD_CASES_FAILD, error: dashboardCases.code });
    }
  });
};

const loadMoreDashboardCaseAction = (value: boolean) => (dispatch: any) => {
  dispatch({ type: actionTypes.LOAD_MORE, value: value });
};

export { getDashboardCasesAction, loadMoreDashboardCaseAction };
