import { getCaseById, newCase, uploadCaseDetail, deleteCase } from './casesService';
import * as actionTypes from './casesActionTypes';
import * as dashboardActionTypes from '../dashboard/dashboardActionTypes';
import { caseDetailUploadFinished, caseDetailUploadStarted } from './detail/caseDetailReducer';
import { checkErrorStatus } from '../ErrorHandling';

const getCaseByIdAction = (caseId: number) => (dispatch: any) => {
  dispatch({ type: actionTypes.GET_CASE_BY_ID });
  return getCaseById(caseId).then((c) => {
    if (checkErrorStatus(c.code, dispatch)) {
      dispatch({ type: actionTypes.GET_CASE_BY_ID_SUCCESS, case: c.case });
      return c.case;
    } else {
      dispatch({ type: actionTypes.GET_CASE_BY_ID_FAILD, error: c.code });
      return c.case;
    }
  });
};

const newCaseAction = (onSuccess: (id: number) => void) => (dispatch: any) => {
  dispatch({ type: actionTypes.NEW_CASE });

  return newCase().then((c) => {
    if (checkErrorStatus(c.code, dispatch) && c.data) {
      dispatch({ type: actionTypes.NEW_CASE_SUCCESS });
      dispatch({ type: dashboardActionTypes.CREATE_DASHBOARD_CASE, caseId: c.data });
      getCaseByIdAction(c.data);
      onSuccess(c.data);
    } else {
      dispatch({ type: actionTypes.NEW_CASE_FAILED, error: c.code });
    }
  });
};

const uploadCaseDetailActions =
  (caseId: number, stackOfChanges: Array<any>[]) => (dispatch: any) => {
    dispatch(caseDetailUploadStarted());
    return uploadCaseDetail(caseId, stackOfChanges).then((resp) => {
      const rev = stackOfChanges[stackOfChanges.length - 1][0];
      dispatch(caseDetailUploadFinished(rev));
      if (checkErrorStatus(resp.code, dispatch)) {
        if (resp.data !== undefined) {
          dispatch({ type: actionTypes.UPDATE_CASE_SUCCESS, case: resp.data });
          dispatch({ type: dashboardActionTypes.UPDATE_DASHBOARD_CASE, case: resp.data });
        }
        return true;
      } else {
        return false;
      }
    });
  };

const deleteCaseActions = (deleteCases: Array<number>) => (dispatch: any) => {
  dispatch({ type: actionTypes.DELETE_CASE });
  return deleteCase(deleteCases).then((resp) => {
    if (checkErrorStatus(resp.code, dispatch)) {
      dispatch({ type: actionTypes.DELETE_CASE_SUCCESS });
      deleteCases.forEach((element) => {
        dispatch({ type: dashboardActionTypes.DELETE_DASHBOARD_CASE, caseId: element });
      });
      return resp.data;
    } else {
      dispatch({ type: actionTypes.DELETE_CASE_FAILED, error: 11 });
      return false;
    }
  });
};

const closeReopenCaseActions =
  (caseId: number, stackOfChanges: Array<any>[]) => (dispatch: any) => {
    dispatch(caseDetailUploadStarted());
    return uploadCaseDetail(caseId, stackOfChanges).then((resp) => {
      const rev = stackOfChanges[stackOfChanges.length - 1][0];
      dispatch(caseDetailUploadFinished(rev));
      if (checkErrorStatus(resp.code, dispatch)) {
        if (resp.data !== undefined) {
          dispatch({ type: actionTypes.UPDATE_CASE_SUCCESS, case: resp.data });
          dispatch({ type: dashboardActionTypes.UPDATE_DASHBOARD_CASE, case: resp.data });
        }
        return true;
      } else {
        return false;
      }
    });
  };

export {
  getCaseByIdAction,
  newCaseAction,
  uploadCaseDetailActions,
  deleteCaseActions,
  closeReopenCaseActions,
};
