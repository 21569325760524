import { Text, TextType } from '../../components/dsm/Text';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { TeethComponent } from './TeethComponent';
import { CaseDetailContext } from '../CaseDetailProvider';
import { DetailsLabWork } from '../../ui-model/CaseDetail';
import { ReactComponent as RightScrollIcon } from '../../../icons/rightScroll.svg';
import { ReactComponent as LeftScrollIcon } from '../../../icons/leftScroll.svg';
import { CLOSED, DRAFT } from '../caseStates';
import { setCaseDetailUpdatePhases } from '../../../data/cases/detail/caseDetailReducer';
import { useDispatch } from 'react-redux';
import SearchableDropdown, {
  SearchableDropdownValue,
} from '../../components/dsm/SearchableDropdown';
import { PhaseStripe } from './phase/PhaseStripe';
import {
  PhaseUiModel,
  PhaseUiModelPhase,
  mapPhaseUiModelsFromWorkPhaseGroups,
} from '../../ui-model/PhaseUiModel';
import { OPEN, STARTED } from '../../../data/cases/detail/PhaseStatus';
import { TemplateUiModel } from '../../ui-model/worktype/TemplateUiModel';
import { useExtensionBoxViewModel } from '../worksheet/ExtensionBoxViewModel';
import { useExtensionRepositoryImplementation } from '../../../data/worktype/extension/ExtensionRepositoryImplementation';
import { useTemplateRepositoryImplementation } from '../../../data/worktype/templates/TemplateRepositoryImplementation';
import { useTemplatesViewModel } from '../../settings/worktypes/worktypeitem/TemplatesViewModel';
import { LANGUAGE_KEYS } from '../../translations/languageKeys';
import useTranslate from '../../translations/useTranslate';

type LabWorkOverviewProps = {
  caseId: number;
  labWork: DetailsLabWork;
  isEnabledAssigne: boolean;
  assignPhase: (phase: PhaseUiModelPhase, x: number, y: number) => void;
  changeErrorStatus: (phase: PhaseUiModelPhase, x: number, y: number) => void;
  onStatusChange: (phase: PhaseUiModelPhase, status: PhaseUiModelPhase['status']) => void;
  duplicatePhase: (phase: PhaseUiModel) => void;
  deletePhase: (phase: PhaseUiModel) => void;
  onAddPhase: (phaseBefore: PhaseUiModel) => void;
};

// The first parameter is the caseID, the second parameter is labworkID and the last parameter is phase position.
// If the user navigates to another page and back to the case, then from this value we know what position the phases were in.
const phasWrapperPositions: Map<number, Map<number, number>> = new Map();

export function LabWorkOverView(props: LabWorkOverviewProps) {
  const translate = useTranslate();
  const [phaseStripeWrapper, setPhaseStripeWrapper] = useState<HTMLDivElement | null>(null);
  const { caseDetailObj } = useContext(CaseDetailContext);
  const [selectedTemplate, setSelectedTemplate] = useState({ kind: 'Existing', id: -1 });
  const [isSelectedTemplate, setIsSelectedTemplate] = useState(false);
  const [isCustomPhase, setIsCustomPhase] = useState(false);

  const repo = useExtensionRepositoryImplementation();
  const { extensions, mapExtrasFromExtensionListByLabWork } = useExtensionBoxViewModel(repo);

  const repoTemplates = useTemplateRepositoryImplementation();
  const { getSortTemplatesByWorkTypeId } = useTemplatesViewModel(repoTemplates);

  const templates: TemplateUiModel[] = getSortTemplatesByWorkTypeId(
    Number(props.labWork?.workType?.id),
  );

  const extraKeys = mapExtrasFromExtensionListByLabWork(caseDetailObj, props.labWork.labWorkId);

  const dispatch = useDispatch();

  useEffect(() => {
    if (caseDetailObj.status === DRAFT) updateTemplateElements();
    if (props.labWork.phases !== undefined && props.labWork.phases !== null) {
      setIsCustomPhase(false);
      setIsSelectedTemplate(true);
    } else {
      setIsSelectedTemplate(false);
    }
  }, [selectedTemplate]);

  useEffect(() => {
    if (
      phasWrapperPositions !== undefined &&
      phasWrapperPositions !== null &&
      phasWrapperPositions.has(props.caseId) &&
      phasWrapperPositions.get(props.caseId)?.has(props.labWork.labWorkId)
    ) {
      let phasePosition: Map<number, number> = new Map();
      phasePosition = phasWrapperPositions.get(props.caseId)!;
      scrollToPosition(phasePosition.get(props.labWork.labWorkId)!);
    } else {
      phaseStripeWrapper && updatePositionOnPhaseStripe();
    }
  }, [phaseStripeWrapper]);

  function getName(id: number, value: number) {
    const n = extensions?.find((e) => {
      return e.id === id;
    })?.name;

    return value > 1 ? `${value}x ${n}` : n;
  }

  function updatePositionOnPhaseStripe() {
    if (
      !props.labWork.phases?.some((element) => {
        return element === null;
      })
    ) {
      let startedIndex;
      const nextPhase = props.labWork.phases?.find(
        (phase) => phase.status === STARTED || phase.status === OPEN,
      );

      if (nextPhase !== undefined && nextPhase !== null) {
        startedIndex = props.labWork.phases?.indexOf(nextPhase);
      }

      if (startedIndex != undefined) {
        scrollToPosition(startedIndex - 1);
        let phasePosition: Map<number, number> = new Map();
        if (phasWrapperPositions.has(props.caseId)) {
          phasePosition = phasWrapperPositions.get(props.caseId)!;
          phasePosition.set(props.labWork.labWorkId, startedIndex - 1);
        } else {
          phasePosition.set(props.labWork.labWorkId, startedIndex - 1);
        }
        phasWrapperPositions.set(props.caseId, phasePosition);
        return;
      }
    } else {
      const phasePosition: Map<number, number> = new Map();
      phasePosition.set(props.labWork.labWorkId, 0);
      phasWrapperPositions.set(props.caseId, phasePosition);
    }
  }

  function scrollToPosition(position: number) {
    if (position < 0) {
      return;
    }

    if (phaseStripeWrapper) {
      const element = (phaseStripeWrapper.childNodes as NodeListOf<HTMLDivElement>)[0];
      if (element) {
        sideScroll(phaseStripeWrapper, 1, Number.MAX_VALUE, -Number.MAX_VALUE);
        const offset = element.offsetWidth * (position + 1);
        sideScroll(phaseStripeWrapper, 1, offset, offset);
      }
    }
  }

  function updateTemplateElements() {
    if (selectedTemplate.kind === 'Existing' && selectedTemplate.id !== -1) {
      const template = templates.find((template) => template.id === selectedTemplate.id);
      dispatch(
        setCaseDetailUpdatePhases({
          labworkId: props.labWork.labWorkId,
          templateId: template?.id || -1,
          phases: template?.phases || [],
        }),
      );
      updatePositionOnPhaseStripe();
    } else {
      if (selectedTemplate.id === -1 && selectedTemplate.kind === 'CreateNew') {
        dispatch(
          setCaseDetailUpdatePhases({
            labworkId: props.labWork.labWorkId,
            templateId: -1,
            phases: [],
          }),
        );
        updatePositionOnPhaseStripe();
      }
    }
  }

  const sideScroll = (element: HTMLDivElement, speed: number, distance: number, step: number) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };

  function leftScroll() {
    if (phaseStripeWrapper) {
      const element = (phaseStripeWrapper.childNodes as NodeListOf<HTMLDivElement>)[0];
      if (element) {
        let phasePosition: Map<number, number> = new Map();
        phasePosition = phasWrapperPositions.get(props.caseId)!;
        if (phasePosition !== undefined) {
          phasePosition.set(
            props.labWork.labWorkId,
            phasePosition.get(props.labWork.labWorkId)! - 1,
          );
          phasWrapperPositions.set(props.caseId, phasePosition);
          sideScroll(phaseStripeWrapper, 25, element.offsetWidth, -10);
        }
      }
    }
  }

  function rightScroll() {
    if (phaseStripeWrapper) {
      const element = (phaseStripeWrapper.childNodes as NodeListOf<HTMLDivElement>)[0];
      if (element) {
        let phasePosition: Map<number, number> = new Map();
        phasePosition = phasWrapperPositions.get(props.caseId)!;
        if (phasePosition !== undefined) {
          phasePosition.set(
            props.labWork.labWorkId,
            phasePosition.get(props.labWork.labWorkId)! + 1,
          );
          phasWrapperPositions.set(props.caseId, phasePosition);
          sideScroll(phaseStripeWrapper, 25, element.offsetWidth, 10);
        }
      }
    }
  }

  const selectedTemplateOption: SearchableDropdownValue | null = useMemo(() => {
    if (selectedTemplate.kind === 'Existing') {
      const template = templates[0];
      return { key: template?.id, label: template?.name || '' };
    } else return null;
  }, [selectedTemplate]);

  const templateOptions: Array<SearchableDropdownValue> = useMemo(() => {
    return templates.map((template) => {
      return {
        key: template.id,
        label: template.name,
      };
    });
  }, [props.labWork?.workType?.templates]);

  const handleTemplateOptionsChange = (value: SearchableDropdownValue) => {
    if (phaseStripeWrapper) phaseStripeWrapper.scrollLeft = 0;
    if (value.key)
      setSelectedTemplate({
        kind: 'Existing',
        id: value.key,
      });
    else
      setSelectedTemplate({
        kind: 'New',
        id: -1,
      });
  };

  const handelOnClickReleatedTemplate = (value: SearchableDropdownValue | null) => {
    if (value !== null) {
      setSelectedTemplate({ kind: 'Existing', id: Number(value.key) });
      updateTemplateElements();
    }
  };

  const handelOnClickCreateNew = () => {
    setIsSelectedTemplate(true);
    setIsCustomPhase(true);
    setSelectedTemplate({ kind: 'CreateNew', id: -1 });
    updateTemplateElements();
  };

  function labWorkNameElem() {
    if (caseDetailObj.status !== DRAFT) {
      const name = props.labWork.labworkName;
      return <FixLabWorkName type={TextType.CAPTION_BOLD}>{name}</FixLabWorkName>;
    } else {
      return (
        <SearchableDropdown
          values={templateOptions}
          selected={selectedTemplateOption}
          onChange={handleTemplateOptionsChange}
          onClickText={handelOnClickReleatedTemplate}
        />
      );
    }
  }

  function newCreateNewTemplate() {
    if (caseDetailObj.status === DRAFT) {
      return (
        <CreateNewContainer>
          <CreateNewText type={TextType.CAPTION_BOLD} onClick={handelOnClickCreateNew}>
            {translate(LANGUAGE_KEYS.CREATE_NEW)}
          </CreateNewText>
        </CreateNewContainer>
      );
    }
  }

  function getExtensionList() {
    return [...extraKeys.keys()]
      .map((id) => {
        return getName(id, extraKeys.get(id) || 1);
      })
      .join(', ');
  }

  const renderTeethContainer = () => {
    const teeth = caseDetailObj?.tooths?.upper
      ?.concat(caseDetailObj?.tooths?.lower)
      ?.filter((it) => it.labwork == props.labWork.labWorkId);
    return (
      teeth &&
      teeth.length > 0 && (
        <TeethContainer>
          <TeethPart>
            {teeth.map((it, i) => {
              return (
                <TeethComponent
                  quarter={it.quarter}
                  teethp={it.teethp}
                  name={'' + it.quarter + '.' + it.teethp}
                  leftConnection={it.leftConnectionType}
                  type={it.type}
                  key={i}
                ></TeethComponent>
              );
            })}
          </TeethPart>
        </TeethContainer>
      )
    );
  };

  function subCategoryName() {
    let subCategoryName = '';
    props.labWork.extras?.forEach((extra) => {
      if (extra.kind === 'Selectable') {
        extra.values
          .filter((e) => e.selected == true)
          .forEach((ex) => {
            subCategoryName = ex.name !== null ? subCategoryName.concat(ex.name).concat(' ') : '';
          });
      }
    });
    return subCategoryName;
  }

  function getReleatedTemplatePhases() {
    const phases = templates[0]?.phases;
    return phases !== undefined && phases.length > 0 && caseDetailObj.status === DRAFT
      ? mapPhaseUiModelsFromWorkPhaseGroups(phases, true)
      : [];
  }

  return (
    <LabWorkContentBody>
      <ExtraInformationContainer>
        <Text style={{ opacity: '0.7' }} type={TextType.BODY_BOLD}>
          {props?.labWork?.workType?.name + subCategoryName()}
        </Text>
        <Text style={{ opacity: '0.7', marginTop: '4px' }} type={TextType.CAPTION_REGULAR_2}>
          {getExtensionList()}
        </Text>
        {renderTeethContainer()}
      </ExtraInformationContainer>
      <TemplateContainer>
        <TopTemplateContainer>
          <ButtonsContainer>
            <LeftScrollIcon style={{ cursor: 'pointer' }} onClick={leftScroll} />
            <RightScrollIcon style={{ cursor: 'pointer' }} onClick={rightScroll} />
          </ButtonsContainer>
          {!isSelectedTemplate && templateOptions.length > 0 && labWorkNameElem()}
          {!isSelectedTemplate && newCreateNewTemplate()}
        </TopTemplateContainer>
        {!isCustomPhase && (
          <PhaseStripe
            setRef={(ref) => setPhaseStripeWrapper(ref)}
            isEnabledAssigne={props.isEnabledAssigne}
            phases={
              props.labWork.editedPhases !== undefined && props.labWork.editedPhases !== null
                ? [...props.labWork.editedPhases]
                : getReleatedTemplatePhases()
            }
            caseStatus={caseDetailObj.status}
            onAssign={props.assignPhase}
            onErrorStatus={props.changeErrorStatus}
            onStatusChange={(phase, status) => {
              props.onStatusChange(phase, status);
            }}
            onDuplicatePhase={(phase) => props.duplicatePhase(phase)}
            onDeletePhase={(phase) => props.deletePhase(phase)}
            onAddPhase={props.onAddPhase}
            published={caseDetailObj.status !== CLOSED ? true : false}
          />
        )}
      </TemplateContainer>
    </LabWorkContentBody>
  );
}

const LabWorkContentBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-sizing: border-box;
  width: 100%;
  background: rgba(228, 228, 228, 0.2);
  border: 1px solid var(--dts_light_grey);
  border-radius: 3px;
  &:hover {
    border: 1px solid #18a0fb;
  }
`;

const ExtraInformationContainer = styled.div`
  padding: 16px;
  order: 0;
  flex-grow: 0;
`;

const TemplateContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--dts_white);
  padding: 16px;
`;

const TopTemplateContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: start;
  gap: 16px;
  margin-bottom: 8px;
  flex: none;
`;

const TeethContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  margin-top: 8px;
`;

const TeethPart = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 4px 6px;
`;

const CreateNewContainer = styled.div`
  padding: 8px;
`;

const CreateNewText = styled(Text)`
  color: var(--dts_default_blue);
  cursor: pointer;
  &:hover {
    color: var(--dts_hover_blue);
  }
`;

const FixLabWorkName = styled(Text)`
  opacity: 0.7;
  flex: none;
  order: 0;
  flex-grow: 0;
`;
