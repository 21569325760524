import { useDispatch, useSelector } from 'react-redux';
import { DashboardRepository } from '../../domain/dashboard/DashboardRepository';
import { RootState } from '../utils/store';
import { DashboardRepositoryState } from './dashboardReducer';
import React from 'react';
import { getDashboardCasesAction, loadMoreDashboardCaseAction } from './dashboardActions';

const dashboardSelector = (state: RootState) => state.dashboardCases;

const useDashboardRepositoryImplementation = (): DashboardRepository => {
  const {
    dashboardCases,
    totalDashboardItems,
    currentDashboardPage,
    nextDashboardPage,
    loadMore,
    hasMore,
    isLoading,
    isUpdating,
  } = useSelector<RootState, DashboardRepositoryState>(dashboardSelector);
  const dispatch = useDispatch();

  const getDashboardCases = React.useCallback(
    (pageNumber: number, itemNumber: number) =>
      getDashboardCasesAction(pageNumber, itemNumber)(dispatch),
    [dispatch],
  );

  const loadMoreDashboardCase = React.useCallback(
    (value: boolean) => loadMoreDashboardCaseAction(value)(dispatch),
    [dispatch],
  );

  return {
    dashboardCases,
    totalDashboardItems,
    currentDashboardPage,
    nextDashboardPage,
    loadMore,
    hasMore,
    isLoading,
    isUpdating,
    getDashboardCases,
    loadMoreDashboardCase,
  };
};

export { useDashboardRepositoryImplementation };
