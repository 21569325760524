import React, { useEffect } from 'react';
import { Route, Routes, useLocation, Outlet, matchPath, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Billing from '../presentation/billing/Billing';

//Components
import Header from '../presentation/welcome/Header';
import Home from '../presentation/welcome/Home';
import Login from '../presentation/welcome/signUpFlow/Login';
import SignUp from '../presentation/welcome/signUpFlow/SignUp';
import ResetPassword from '../presentation/welcome/signUpFlow/ResetPassword';
import CheckEmail from '../presentation/welcome/signUpFlow/CheckEmail';
import SetPassword from '../presentation/welcome/signUpFlow/SetPassword';
import Lobby from '../presentation/lobby/Lobby';
import Plans from '../presentation/lobby/createLabor/Plans';
import MyProfile from '../presentation/user-management/MyProfile';
import Dashboard from '../presentation/dashboard/Dashboard';
import CaseDetailComponent from '../presentation/case/CaseDetailComponent';
import WorktypeItem from '../presentation/settings/worktypes/worktypeitem/WorktypeItem';
import Phases from '../presentation/settings/phases/Phases';
import WorkTypes from '../presentation/settings/worktypes/WorkTypes';
import Reports from '../presentation/reports/Reports';
import ReportsOverview from '../presentation/reports/ReportsOverview';
import UserManagement from '../presentation/user-management/UserManagement';
import InvoiceList from '../presentation/invoice/InvoiceList';
import InvoiceDetail from '../presentation/invoice/InvoiceDetail';
import MyCompanyData from '../presentation/user-management/MyCompanyData';
import FTISteps from '../presentation/welcome/signUpFlow/FTISteps';
import { AddNewComponents } from '../presentation/case/mobile/MobileAddNewComponentProvider';
import ManageSubscription from '../presentation/billing/ManageSubscription';
import E_facturaAccountant from '../presentation/e-factura/E_facturaAccountant';
import E_facturaRedirectFromAnaf from '../presentation/e-factura/E_facturaRedirectFromAnaf';
import Page404 from '../presentation/alert/Page404';
import { MainMenu } from '../presentation/components/menu/MainMenu';

//Repositories
import { useAuthRepositoryImplementation } from '../data/authenticationFlow/authRepositoryImplementation';
import { lobbyRepositoryImplementation } from '../data/lobby/lobbyRepositoryImplementation';
import { useCountiesRepositoryImplementation } from '../data/county/countiesRepositoryImplementation';
import { useExtensionRepositoryImplementation } from '../data/worktype/extension/ExtensionRepositoryImplementation';
import { usePhaseRepositoryImplementation } from '../data/phase/phasesRepositoryImplementation';
import { useTemplateRepositoryImplementation } from '../data/worktype/templates/TemplateRepositoryImplementation';
import { useWorkTypeRepositoryImplementation } from '../data/worktype/WorkTypeRepositoryImplementation';
import {
  useCompaniesRepositoryImplementation,
  useContactsRepositoryImplementation,
} from '../data/user-management/UsersRepositoryImplementation';
import { useBillingRepositoryImplementation } from '../data/billing/billingRepositoryImplementation';
import { useInvoiceRepositoryImplementation } from '../data/invoice/InvoiceRepositoryImplementation';
import { useE_facturaRepositoryImplementation } from '../data/e-factura/E_facturaRepositoryImplementation';
import { useDeviceParameters } from '../presentation/hooks/useDeviceParameters';
import { featuresRepositoryImplementation } from '../data/features/FeaturesRepositoryImplementation';

//Viewmodel
import useRouteViewModel from './RouteViewModel';

//Others
import ProtectedRoute from './ProtectedRoute';
import ProtectedCompanyRoute from './ProtectedCompanyRoute';
import useMediaQuery from '../presentation/hooks/useMediaQuery';
import { DeviceSizeProps, DeviceSizes } from '../presentation/DeviceInformations';
import { useAuth } from './AuthContext';
import { MenuType } from '../presentation/ui-model/menu/MenuUImodel';
import { saveLastUrl } from '../data/utils/saveLastUrl';
import { useSelector } from 'react-redux';
import { RootState } from '../data/utils/store';

const menuSelector = (state: RootState) => state.menu;

const LayoutWithConditionalMenu: React.FC = () => {
  const location = useLocation(); // Az aktuális útvonal lekérdezése

  const { isAuthenticated, userId, language, isFtiFinished, companyId } = useAuth();

  const { i18n } = useTranslation();

  const authRepo = useAuthRepositoryImplementation();
  const lobbyRepo = lobbyRepositoryImplementation();
  const countiesRepo = useCountiesRepositoryImplementation();
  const extensionRepo = useExtensionRepositoryImplementation();
  const phaseRepo = usePhaseRepositoryImplementation();
  const templateRepo = useTemplateRepositoryImplementation();
  const workTypeRepo = useWorkTypeRepositoryImplementation();
  const contactsRepo = useContactsRepositoryImplementation();
  const companiesRepo = useCompaniesRepositoryImplementation();
  const billingRepo = useBillingRepositoryImplementation();
  const invoiceRepo = useInvoiceRepositoryImplementation();
  const e_facturaRepo = useE_facturaRepositoryImplementation();
  const featureRepo = featuresRepositoryImplementation();
  const { setLaboratory, loadDataForApp, loadDataForLaboratory, isEnabledRoute } =
    useRouteViewModel(
      authRepo,
      lobbyRepo,
      countiesRepo,
      extensionRepo,
      phaseRepo,
      templateRepo,
      workTypeRepo,
      contactsRepo,
      companiesRepo,
      billingRepo,
      invoiceRepo,
      e_facturaRepo,
      featureRepo,
    );

  const hideMenuOnRoutes = ['/login', '/signup'];
  const shouldHideMenuByRoute = hideMenuOnRoutes.includes(location.pathname);

  const isMobile = useMediaQuery(DeviceSizes.mobile);
  const { isTabletLarge, isTabletSmall } = useDeviceParameters();

  const shouldHideMenu = shouldHideMenuByRoute && isMobile;

  const [isEfacturaAccountRoute, setIsEfacturaAccountRoute] = React.useState<boolean>(false);

  const [device, setDevice] = React.useState<MenuType>(MenuType.DESKTOP);

  useEffect(() => {
    i18n.changeLanguage(language !== null ? language : 'en');
  }, [language]);

  useEffect(() => {
    const pathParts = location.pathname.split('/');
    const currentCompanyId = pathParts[1];
    if (currentCompanyId) {
      const parsedCompanyId = Number(currentCompanyId);
      if (!isNaN(parsedCompanyId)) {
        if (!isAuthenticated && localStorage.getItem('redirectAfterLogin') === null) {
          saveLastUrl();
        }
        setLaboratory(Number(currentCompanyId));
      } else {
        setLaboratory(null);
      }
    } else {
      setLaboratory(null);
    }
  }, [location.pathname, lobbyRepo.lobbyCompanies]);

  React.useEffect(() => {
    if (isAuthenticated) {
      loadDataForApp(userId);
    }
  }, [isAuthenticated]);

  React.useEffect(() => {
    if (companyId !== null) {
      loadDataForLaboratory();
    }
  }, [companyId]);

  React.useEffect(() => {
    if (
      location.pathname.includes('/efactura') &&
      !isMobile &&
      !isTabletLarge &&
      !isTabletSmall &&
      !isAuthenticated
    ) {
      setIsEfacturaAccountRoute(true);
    } else {
      setIsEfacturaAccountRoute(false);
    }
  }, [location.pathname, isAuthenticated]);

  React.useEffect(() => {
    getLabDevice();
  }, [isMobile, isTabletLarge, isTabletSmall]);

  const getLabDevice = () => {
    if (isMobile) {
      setDevice(MenuType.MOBILE_LAB);
    } else if (isTabletLarge || isTabletSmall) {
      setDevice(MenuType.TABLET_LAB);
    } else {
      setDevice(MenuType.DESKTOP_LAB);
    }
  };

  const setMainHeaderVisibility = (): boolean => {
    const visibleMenu = ['/password/recover/:any/*'];
    return visibleMenu.some((path) => matchPath(path, location.pathname));
  };

  return (
    <>
      {!shouldHideMenu && (!isAuthenticated || setMainHeaderVisibility()) && <Header />}

      <Routes>
        <Route element={<MainLayout />}>
          {!isAuthenticated && <Route path='/' element={<Home />} />}
          {!isAuthenticated && <Route path='/login' element={<Login />} />}
          {!isAuthenticated && <Route path='/signup' element={<SignUp />} />}
          {!isAuthenticated && <Route path='/reset-password' element={<ResetPassword />} />}
          {!isAuthenticated && <Route path='/checkemail' element={<CheckEmail />} />}
          <Route path='/password/set/:userIdentifier' element={<SetPassword />} />
          <Route path='/password/recover/:userIdentifier' element={<SetPassword />} />
          {isEfacturaAccountRoute && (
            <Route path='/efactura/:hash' element={<E_facturaAccountant />} />
          )}
          <Route
            path='/efactura/registered'
            element={
              isEfacturaAccountRoute ? <E_facturaAccountant /> : <E_facturaRedirectFromAnaf />
            }
          />
        </Route>
        <Route element={<Layout />}>
          <Route
            path='/'
            element={<ProtectedRoute element={isFtiFinished ? <Lobby /> : <FTISteps />} />}
          />
          <Route path='/plans' element={<ProtectedRoute element={<Plans />} />} />
          <Route path='/myprofile' element={<ProtectedRoute element={<MyProfile />} />} />

          <Route element={<CompanyLayout />}>
            <Route
              path='/:companyId/myprofile'
              element={
                <ProtectedCompanyRoute
                  element={<MyProfile />}
                  pathName=''
                  deviceType={device}
                  isEnabledRoute={isEnabledRoute}
                />
              }
            />
            <Route
              path='/:companyId'
              element={
                <ProtectedCompanyRoute
                  element={<Dashboard />}
                  pathName=''
                  deviceType={device}
                  isEnabledRoute={isEnabledRoute}
                />
              }
            />
            <Route
              path='/:companyId/case/:id/*'
              element={
                <ProtectedCompanyRoute
                  element={<CaseDetailComponent />}
                  pathName='/case/:id/*'
                  deviceType={device}
                  isEnabledRoute={isEnabledRoute}
                />
              }
            />
            <Route
              path='/:companyId/settings/worktype'
              element={
                <ProtectedCompanyRoute
                  element={<WorkTypes />}
                  pathName='/settings/worktype'
                  deviceType={device}
                  isEnabledRoute={isEnabledRoute}
                />
              }
            />
            <Route
              path='/:companyId/settings/worktype/:id/*'
              element={
                <ProtectedCompanyRoute
                  element={<WorktypeItem />}
                  pathName='/settings/worktype'
                  deviceType={device}
                  isEnabledRoute={isEnabledRoute}
                />
              }
            />
            <Route
              path='/:companyId/settings/phases'
              element={
                <ProtectedCompanyRoute
                  element={<Phases />}
                  pathName='/settings/phases'
                  deviceType={device}
                  isEnabledRoute={isEnabledRoute}
                />
              }
            />
            <Route
              path='/:companyId/reports'
              element={
                <ProtectedCompanyRoute
                  element={<Reports />}
                  pathName='/reports'
                  deviceType={device}
                  isEnabledRoute={isEnabledRoute}
                />
              }
            />
            <Route
              path='/:companyId/reports/reportsoverview'
              element={
                <ProtectedCompanyRoute
                  element={<ReportsOverview />}
                  pathName='/reports/reportsoverview'
                  deviceType={device}
                  isEnabledRoute={isEnabledRoute}
                />
              }
            />
            <Route
              path='/:companyId/settings/users'
              element={
                <ProtectedCompanyRoute
                  element={<UserManagement />}
                  pathName='/settings/users'
                  deviceType={device}
                  isEnabledRoute={isEnabledRoute}
                />
              }
            />
            <Route
              path='/:companyId/settings/users/:id'
              element={
                <ProtectedCompanyRoute
                  element={<UserManagement />}
                  pathName='/settings/users'
                  deviceType={device}
                  isEnabledRoute={isEnabledRoute}
                />
              }
            />
            <Route
              path='/:companyId/invoice'
              element={
                <ProtectedCompanyRoute
                  element={<InvoiceList />}
                  pathName='/invoice'
                  deviceType={device}
                  isEnabledRoute={isEnabledRoute}
                />
              }
            />
            <Route
              path='/:companyId/invoice/:invoiceId'
              element={
                <ProtectedCompanyRoute
                  element={<InvoiceDetail />}
                  pathName='/invoice'
                  deviceType={device}
                  isEnabledRoute={isEnabledRoute}
                />
              }
            />
            <Route
              path='/:companyId/settings/mycompany'
              element={
                <ProtectedCompanyRoute
                  element={<MyCompanyData />}
                  pathName='/settings/mycompany'
                  deviceType={device}
                  isEnabledRoute={isEnabledRoute}
                />
              }
            />
            <Route
              path='/:companyId/settings/billing'
              element={
                <ProtectedCompanyRoute
                  element={<Billing />}
                  pathName='/settings/billing'
                  deviceType={device}
                  isEnabledRoute={isEnabledRoute}
                />
              }
            />
            <Route
              path='/:companyId/case/:id/newComponent/:componentType'
              element={
                <ProtectedCompanyRoute
                  element={<AddNewComponents />}
                  pathName='/case/:id/newComponent/:componentType'
                  deviceType={device}
                  isEnabledRoute={isEnabledRoute}
                />
              }
            />
            <Route
              path='/:companyId/settings/billing/manage-subscription'
              element={
                <ProtectedCompanyRoute
                  element={<ManageSubscription />}
                  pathName='/settings/billing'
                  deviceType={device}
                  isEnabledRoute={isEnabledRoute}
                />
              }
            />
          </Route>
          <Route path='*' element={<Page404 />} />
        </Route>
      </Routes>
    </>
  );
};

const MainLayout: React.FC = () => {
  const { mobileSmallSize, mobileSize, tabletLargeSize, tabletSmallSize, desktopSize } =
    useDeviceParameters();

  const hideMenuOnRoutes = ['/login'];
  const shouldHideMenuByRoute = hideMenuOnRoutes.includes(location.pathname);

  return (
    <Wrapper
      className={`${shouldHideMenuByRoute && 'not-needed-mobile-menu'}`}
      style={{ display: 'flex' }}
      mobile_small={mobileSmallSize}
      mobile={mobileSize}
      tablet_small={tabletSmallSize}
      tablet_large={tabletLargeSize}
      desktop={desktopSize}
    >
      <MainContentWrapper style={{ flex: 1 }}>
        <Outlet />
      </MainContentWrapper>
    </Wrapper>
  );
};

const Layout: React.FC = () => {
  const { isAuthenticated, isFtiFinished } = useAuth();

  const menuState = useSelector<RootState, { open: number }>(menuSelector);

  const navigate = useNavigate();

  const { mobileSmallSize, mobileSize, tabletLargeSize, tabletSmallSize, desktopSize } =
    useDeviceParameters();

  const hideMenuOnRoutes = ['/plans'];
  const shouldHideMenuByRoute = hideMenuOnRoutes.includes(location.pathname);

  const shouldHideMenu = shouldHideMenuByRoute;

  const notNeedPage404 = ['/login', '/signup', '/reset-password', '/checkemail'];
  const notNeedPage404ByRoute = notNeedPage404.some((word) => location.pathname.includes(word));

  useEffect(() => {
    if (isAuthenticated && notNeedPage404ByRoute) {
      navigate('/');
    }
  }, [isAuthenticated, notNeedPage404ByRoute, navigate]);

  return (
    <Wrapper
      style={{ display: 'flex' }}
      mobile_small={mobileSmallSize}
      mobile={mobileSize}
      tablet_small={tabletSmallSize}
      tablet_large={tabletLargeSize}
      desktop={desktopSize}
    >
      {isAuthenticated && isFtiFinished && !shouldHideMenu && <MainMenu />}
      <ContentWrapper
        style={{ flex: 1 }}
        menuState={menuState.open}
        hasMenu={isAuthenticated && !shouldHideMenu}
      >
        <Outlet />
      </ContentWrapper>
    </Wrapper>
  );
};

const CompanyLayout: React.FC = () => {
  const { isAuthenticated, companyId, isLoadingCompanyData } = useAuth();

  if (isAuthenticated && isLoadingCompanyData) {
    return <></>;
  }

  if (companyId === null) {
    return <Page404 />;
  }

  return <>{companyId && <Outlet />}</>;
};

const Wrapper = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    grid-row-start: content;
    grid-column-start: content;
    grid-row-end: content;
    grid-column-end: content;
    display: flex;
    flex-direction: row;
    position: relative;
    flex-grow: 1;
    background-color: var(--dts_white);
    //Desktop
    @media (min-width: ${desktop}px) {
      // height: calc(100% - 68px);
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
      // height: calc(100% - 68px);
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      height: calc(100% - 98px);
      &.not-needed-mobile-menu {
        height: 100%;
      }
    }
    // Mobile small 
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const MainContentWrapper = styled.div`
  width: 100%;

  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  background-color: var(--dts_white);
`;

const ContentWrapper = styled.div<{ menuState: number; hasMenu: boolean }>(
  ({ menuState, hasMenu }) => `
  width: 100%;
  box-sizing: border-box;
  background-color: var(--dts_withe_background);
  @media (min-width: 1440px) {
    margin-left: ${
      hasMenu
        ? menuState === 1
          ? 'var(--leftSidebarWidth);'
          : 'var(--leftSidebarClosedWidth);'
        : '0px;'
    }
    max-width: ${
      hasMenu
        ? menuState === 1
          ? 'calc(100% - var(--leftSidebarWidth));'
          : 'calc(100% - var(--leftSidebarClosedWidth));'
        : '100%;'
    } 
  }
`,
);

export default LayoutWithConditionalMenu;
