import { CaseRepository } from '../../domain/cases/CaseRepository';

type UpdateCasesRepository = Pick<CaseRepository, 'updateCase'>;

const uploadCaseUpdateUseCase = (
  repo: UpdateCasesRepository,
  caseId: number,
  stackOfChanges: Array<string | number>[],
) => {
  return repo.updateCase(caseId, stackOfChanges);
};

export { uploadCaseUpdateUseCase };
export type { UpdateCasesRepository };
