import { CaseRepository } from '../../domain/cases/CaseRepository';
import React from 'react';
import { CaseDetail } from '../ui-model/CaseDetail';
import { useDispatch } from 'react-redux';
import { setCaseDetailStatus } from '../../data/cases/detail/caseDetailReducer';
import { ACTIVE } from './caseStates';
import { FeaturesRepository } from '../../domain/features/FeaturesRepository';
import { FeatureId } from '../../domain/features/Features';
import { getFeatureByIdUseCase } from '../../useCases/features/getFeatureUseCase';

function useCaseDetailFooterPopUpViewModel(repo: CaseRepository, featuresRepo: FeaturesRepository) {
  const dispatch = useDispatch();
  const createCase = React.useCallback(() => {
    dispatch(setCaseDetailStatus(ACTIVE));
  }, [repo.newCase]);

  const saveCaseAsDraft = React.useCallback(
    (c: CaseDetail) => {
      console.log('useCaseDetailFooterPopUpViewModel');
      console.log(c);
      // let newCase= getCaseCreate(c, DRAFT)
      // createCaseUseCase({newCase: repo.newCase}, newCase);
    },
    [repo.newCase],
  );

  const isEnabledPublichAction = (userRole: number) => {
    return getFeatureByIdUseCase(featuresRepo, userRole, FeatureId.PUBLISH_CASE_ACTION);
  };

  return {
    isLoading: repo.isLoading,
    isUpdating: repo.isUpdating,
    error: repo.error,
    createCase,
    saveCaseAsDraft,
    isEnabledPublichAction,
  };
}

export { useCaseDetailFooterPopUpViewModel };
