import { DashboardRepository } from '../../domain/dashboard/DashboardRepository';

type GetDashboardCasesRepository = Pick<
  DashboardRepository,
  'getDashboardCases' | 'loadMoreDashboardCase'
>;

const getDashboardCasesUseCase = (
  repo: GetDashboardCasesRepository,
  pageNumber: number,
  itemNumber: number,
) => {
  repo.getDashboardCases(pageNumber, itemNumber);
};

const loadMoreDashboardCaseUseCase = (repo: GetDashboardCasesRepository, value: boolean) => {
  repo.loadMoreDashboardCase(value);
};

export { getDashboardCasesUseCase, loadMoreDashboardCaseUseCase };
