import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { DeviceSizeProps } from '../../DeviceInformations';
import { MenuElement } from './MenuElement';
import { ProfileMenuElement } from './ProfileMenu';
import { MenuHeader } from './MenuHeader';
import { MenuFooter } from './MenuFooter';
import packageJson from './../../../../package.json';
import { Text, TextType } from '../dsm/Text';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as actionTypes from './menuActionTypes';
import { useMenuViewModel } from './MenuViewModel';
import { MenuUiItem } from '../../ui-model/menu/MenuUImodel';
import { featuresRepositoryImplementation } from '../../../data/features/FeaturesRepositoryImplementation';
import { useDeviceParameters } from '../../hooks/useDeviceParameters';
import useTranslate from '../../translations/useTranslate';
import { LANGUAGE_KEYS } from '../../translations/languageKeys';
import { RootState } from '../../../data/utils/store';
import { useAuthRepositoryImplementation } from '../../../data/authenticationFlow/authRepositoryImplementation';
import { useAuth } from '../../../routeFiles/AuthContext';

const menuSelector = (state: RootState) => state.menu;

export const MainMenu = () => {
  const dispatch = useDispatch();
  const menuState = useSelector<RootState, { open: number }>(menuSelector);
  const location = useLocation();
  const { companyId, userRole, companyName, validSubscription } = useAuth();
  const {
    isTabletSmall,
    isTabletLarge,
    isMobile,
    mobileSmallSize,
    mobileSize,
    tabletLargeSize,
    tabletSmallSize,
    desktopSize,
  } = useDeviceParameters();

  const translate = useTranslate();

  const authRepo = useAuthRepositoryImplementation();
  const featureRepo = featuresRepositoryImplementation();

  const [menuItems, setMenuItems] = useState<Array<MenuUiItem>>([]);
  const [profileMenuItem, setProfileMenuItem] = useState<Array<MenuUiItem>>([]);

  const { getDesktopMenu, getTabletMenu, getMobileMenu, getProfileMenu } = useMenuViewModel(
    authRepo,
    featureRepo,
  );

  useEffect(() => {
    setProfileMenuItem(getProfileMenu(companyId));
    if (isMobile) {
      setMenuItems(getMobileMenu(userRole, companyName, companyId));
    } else if (isTabletSmall || isTabletLarge) {
      setMenuItems(getTabletMenu(userRole, companyName, companyId));
    } else {
      setMenuItems(getDesktopMenu(userRole, companyName, companyId));
    }
  }, [menuState, userRole, companyName, companyId, isMobile, isTabletSmall, isTabletLarge]);

  return (
    <>
      <MenuWrapper
        mobile_small={mobileSmallSize}
        mobile={mobileSize}
        tablet_small={tabletSmallSize}
        tablet_large={tabletLargeSize}
        desktop={desktopSize}
      >
        <ContextualNavigation
          mobile_small={mobileSmallSize}
          mobile={mobileSize}
          tablet_small={tabletSmallSize}
          tablet_large={tabletLargeSize}
          desktop={desktopSize}
          style={{
            width:
              menuState.open === 0 ? 'var(--leftSidebarClosedWidth)' : 'var(--leftSidebarWidth)',
          }}
        >
          <ContextualNavigationContent
            mobile_small={mobileSmallSize}
            mobile={mobileSize}
            tablet_small={tabletSmallSize}
            tablet_large={tabletLargeSize}
            desktop={desktopSize}
          >
            <Menu
              mobile_small={mobileSmallSize}
              mobile={mobileSize}
              tablet_small={tabletSmallSize}
              tablet_large={tabletLargeSize}
              desktop={desktopSize}
            >
              <MenuNav
                mobile_small={mobileSmallSize}
                mobile={mobileSize}
                tablet_small={tabletSmallSize}
                tablet_large={tabletLargeSize}
                desktop={desktopSize}
              >
                <MenuHeader companyId={companyId} />
                <MenuElements
                  className={`${companyId === null && 'before-lab'}`}
                  mobile_small={mobileSmallSize}
                  mobile={mobileSize}
                  tablet_small={tabletSmallSize}
                  tablet_large={tabletLargeSize}
                  desktop={desktopSize}
                >
                  {menuItems
                    .filter((menu) => !menu.isSettings)
                    .map((item, index) => {
                      let isActive = false;
                      if (item?.subMenus?.some((item) => location.pathname === item.to)) {
                        isActive = true;
                      }
                      return (
                        <MenuElement
                          key={index}
                          to={item.to}
                          isActive={isActive ? isActive : item.isActive}
                          name={item.name}
                          icon={item.icon}
                          subMenus={item.subMenus}
                          menuIsOpen={menuState.open > 0}
                          validSubscription={validSubscription !== null && validSubscription}
                          menuVisibilityExpireSubscription={item.visibilityExpireSubscription}
                        />
                      );
                    })}

                  {companyId !== null && (
                    <SettingText
                      className={`${menuState.open > 0 && 'background'}`}
                      type={TextType.CAPTION_2_BOLD_12}
                    >
                      {menuState.open > 0 && translate(LANGUAGE_KEYS.SETTINGS)}
                    </SettingText>
                  )}
                  {menuItems
                    .filter((menu) => menu.isSettings)
                    .map((item, index) => {
                      let isActive = false;
                      if (location.pathname.startsWith(`${companyId}/settings/`)) {
                        isActive = true;
                      }
                      return (
                        <MenuElement
                          key={index}
                          to={item.to}
                          isActive={isActive ? isActive : item.isActive}
                          name={item.name}
                          icon={item.icon}
                          subMenus={item.subMenus}
                          menuIsOpen={menuState.open > 0}
                          validSubscription={validSubscription !== null && validSubscription}
                          menuVisibilityExpireSubscription={item.visibilityExpireSubscription}
                        />
                      );
                    })}
                </MenuElements>
                <MenuFooterContainer
                  mobile_small={mobileSmallSize}
                  mobile={mobileSize}
                  tablet_small={tabletSmallSize}
                  tablet_large={tabletLargeSize}
                  desktop={desktopSize}
                >
                  {profileMenuItem.length > 0 && (
                    <ProfileMenuElement
                      isActive={false}
                      icon={profileMenuItem[0].icon}
                      name={profileMenuItem[0].name}
                      subMenus={profileMenuItem[0].subMenus}
                    />
                  )}
                  <MenuFooter ver={packageJson.version} />
                  <Borderline
                    mobile_small={mobileSmallSize}
                    mobile={mobileSize}
                    tablet_small={tabletSmallSize}
                    tablet_large={tabletLargeSize}
                    desktop={desktopSize}
                  />
                </MenuFooterContainer>
              </MenuNav>
            </Menu>
          </ContextualNavigationContent>
        </ContextualNavigation>
      </MenuWrapper>
      {menuState.open > 0 && (
        <MenuOverlay
          mobile_small={mobileSmallSize}
          mobile={mobileSize}
          tablet_small={tabletSmallSize}
          tablet_large={tabletLargeSize}
          desktop={desktopSize}
          onClick={() => dispatch({ type: actionTypes.MENU_CLOSE })}
        />
      )}
    </>
  );
};

const MenuWrapper = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    background: var(--dts_white);
    z-index: 199;

    position: fixed;
    top: 0;
    height: 100%;

    //Desktop
    @media (min-width: ${desktop}px) {
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const ContextualNavigation = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    position: relative;
    height: 100%;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
    transition-delay: 0s;
    transition-property: width;
    z-index: 1;
    margin-left: 0px;
    //Desktop
    @media (min-width: ${desktop}px) {}
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {}
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
  `,
);

const ContextualNavigationContent = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    visibility: visible;
    transition-duration: 0s;
    transition-timing-function: ease;
    transition-delay: 0s;
    transition-property: none;
    opacity: 1;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    //Desktop
    @media (min-width: ${desktop}px) {}
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {}
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
  `,
);

const Menu = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    height: 100%;
    display: flex;
    flex-direction: column;
    min-width: 191px;
    //Desktop
    @media (min-width: ${desktop}px) {
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const MenuNav = styled.nav<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: var(--leftSidebarWidth);
    //Desktop
    @media (min-width: ${desktop}px) {
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const MenuElements = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    position: relative;
    height: 100%;
    outline-style: none;
    &.before-lab {
      height: auto;
    }
    //Desktop
    @media (min-width: ${desktop}px) {
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const Borderline = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: var(--dts_light_grey);
    width: 1px;
    height: 100%;
    left: 100%;
    top: 0;
    bottom: 0;
    position: absolute;
    outline-style: none;
    //Desktop
    @media (min-width: ${desktop}px) {}
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {}
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const MenuOverlay = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    //Desktop
    @media (min-width: ${desktop}px) {
      display: none;
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
      display: block;
      position: absolute;
      left: 0%;
      right: 0%;
      top: 0%;
      bottom: 0%;
      z-index: 198;
      background: #000000;
      mix-blend-mode: normal;
      opacity: 0.18;
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const MenuFooterContainer = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    //Desktop
    @media (min-width: ${desktop}px) {
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const SettingText = styled(Text)`
  padding: 6px 16px 6px 16px;
  min-height: 24px;
  line-height: 24px;
  &.background {
    background: var(--dts_withe_background);
  }
`;
