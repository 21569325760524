import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../authenticationFlow/authReducer';
import lobbyReducer from '../lobby/lobbyReducer';
import { contactsReducer, companiesReducer } from '../user-management/usersReducer';
import menuReducer from '../../presentation/components/menu/menuReducer';
import alertReducer from '../alert/alertReducer';
import billingReducer from '../billing/billingReducer';
import caseReducer from '../cases/caseReducer';
import workTypeReducer from '../worktype/workTypeReducer';
import countyReducer from '../county/countyReducer';
import extensionReducer from '../worktype/extension/extensionReducer';
import phaseReducer from '../phase/phaseReducer';
import templateReducer from '../worktype/templates/templateReducer';
import invoiceReducer from '../invoice/invoiceReducer';
import e_facturaReducer from '../e-factura/E-facturaReducer';
import caseDetailReducer from '../cases/detail/caseDetailReducer';
import teethReducer from '../worktype/teeth/teethReducer';
import reportReducer from '../reports/reportReducer';
import dashboardReducer from '../dashboard/dashboardReducer';

const store = configureStore({
  reducer: {
    auth: authReducer,
    lobby: lobbyReducer,
    contacts: contactsReducer,
    companies: companiesReducer,
    menu: menuReducer,
    alert: alertReducer,
    billing: billingReducer,
    cases: caseReducer,
    dashboardCases: dashboardReducer,
    workType: workTypeReducer,
    counties: countyReducer,
    extension: extensionReducer,
    phase: phaseReducer,
    template: templateReducer,
    invoice: invoiceReducer,
    eFactura: e_facturaReducer,
    casesDetail: caseDetailReducer,
    teeth: teethReducer,
    report: reportReducer,
  },
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware({
  //     serializableCheck: false,
  //   }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
