import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CaseDetail, DetailsLabWork, Tooths } from '../../../presentation/ui-model/CaseDetail';
import { CaseDetailRepository } from '../../../domain/cases/CaseDetailRepository';
import * as updateType from '../detail/caseDetailActionTypes';
import * as phaseType from '../detail/caseDetailPhaseTypes';
import { Extra } from '../../../domain/Extra';
import { DRAFT } from '../../../presentation/case/caseStates';
import { Gender } from '../../../domain/Gender';
import { WorkPhaseGroup } from '../../../domain/WorkPhaseGroup';
import { WorkerData } from '../../../domain/user-management/ContactData';
import {
  mapPhaseUiModelsFromWorkPhaseGroups,
  PhaseUiModel,
} from '../../../presentation/ui-model/PhaseUiModel';
import { Template } from '../../../domain/worktype/templates/Template';

export type CaseDetailRepositoryState = Omit<CaseDetailRepository, 'updateCase'>;

const initialState: CaseDetailRepositoryState = {
  caseUi: null,
  loading: false,
  stackOfChanges: [],
  revision: 0,
  needStatusChangeNoti: false,
};

function addChangesToStack(state, change: Array<number | string | any>) {
  const revision = Date.now();
  state.revision = revision;

  state.stackOfChanges = state.stackOfChanges.filter((previousChanges) => {
    return resolve(previousChanges, change);
  });

  state.stackOfChanges = [...state.stackOfChanges, [revision, ...change]];
}

function resolve(oldChange: Array<number | string>, newChange: Array<number | string>): boolean {
  const oldChangeAction = Number(oldChange[1]);
  const newChangeAction = Number(newChange[0]);

  switch (newChangeAction) {
    case updateType.ADD_LAB_WORK:
      return true;
    case updateType.UPDATE_TOOTH:
      return oldChangeAction === updateType.UPDATE_TOOTH && oldChange[2] === newChange[1];
    case updateType.REMOVE_LAB_WORK:
      return true; //!(oldChangeAction === updateType.ADD_LAB_WORK && oldLabWork === newLabWork);

    case updateType.CLIENT:
      return oldChangeAction !== updateType.CLIENT;

    case updateType.PATIENT:
      return oldChangeAction !== updateType.PATIENT;
    default:
      return true;
  }
}

function clearChangesBefore(state: CaseDetailRepositoryState, revision: number) {
  console.log(
    '----------------------------------------clearChangesBefore------------------------------------',
  );
  console.log([...state.stackOfChanges]);
  console.log(revision);
  state.stackOfChanges = state.stackOfChanges.filter((v) => {
    return v[0] > revision.toString();
  });
}

const caseReducer = createSlice({
  name: 'caseDetail',
  initialState,
  reducers: {
    setCaseDetail: (state, action: PayloadAction<CaseDetail | null>) => {
      state.caseUi = action.payload;
      state.revision = state.revision + 1;
    },

    changeClient: (
      state,
      action: PayloadAction<{ id: number; name: string; clientCompanyId: number }>,
    ) => {
      if (state.caseUi !== null) {
        state.caseUi.clientId = action.payload.id;
        state.caseUi.clientCompanyId = action.payload.clientCompanyId;
        state.caseUi.clientName = action.payload.name;
        addChangesToStack(
          state,
          createClientChange(action.payload.id, action.payload.clientCompanyId),
        );
      }
    },

    changePatientName: (state, action: PayloadAction<string>) => {
      if (state.caseUi !== null) {
        state.caseUi.patientName = action.payload;
        addChangesToStack(state, createPatientNameChange(action.payload));
      }
    },

    changePatientAge: (state, action: PayloadAction<number>) => {
      if (state.caseUi !== null) {
        state.caseUi.patientAge = action.payload;
        addChangesToStack(state, createPatientAgeChange(action.payload));
      }
    },

    changePatientGender: (state, action: PayloadAction<Gender['id']>) => {
      if (state.caseUi !== null) {
        state.caseUi.patientGender = action.payload;
        addChangesToStack(state, createPatientGenderChange(action.payload));
      }
    },

    setCaseDetailSelectedTooths: (
      state,
      action: PayloadAction<{ teeth: number; quarter: number }>,
    ) => {
      if (state.caseUi !== null) {
        state.caseUi.selectedTeeth = action.payload;
        // state.caseD = CaseDetail.newInstanceFrom(state.caseD);
      }
    },

    setCaseDetailTooths: (state, action: PayloadAction<Tooths>) => {
      if (state.caseUi !== null) {
        if (state.caseUi.tooths !== action.payload && state.caseUi.selectedLabWork !== null) {
          state.caseUi.tooths = action.payload;
          // state.caseD = CaseDetail.newInstanceFrom(state.caseD);
          const changes = createToothChange(state.caseUi.selectedLabWork, action.payload);
          addChangesToStack(state, changes[0]);
          changes[1].forEach((c) => {
            addChangesToStack(state, c);
          });
        }
      }
    },

    setCaseDetailSelectedLabwork: (state, action: PayloadAction<number>) => {
      if (state.caseUi !== null) {
        state.caseUi.selectedLabWork = action.payload;
        state.caseUi.selectedTeeth = { teeth: -1, quarter: -1 };
      }
    },

    setCaseDetailLabWork: (state, action: PayloadAction<DetailsLabWork>) => {
      if (state.caseUi !== null && state.caseUi.labworks !== null) {
        state.caseUi.labworks = [...state.caseUi.labworks, action.payload];
        addChangesToStack(state, createLabworkChange(action.payload));
      }
    },

    setCaseDetailRemoveLabWork: (state, action: PayloadAction<number>) => {
      if (state.caseUi !== null && state.caseUi.labworks !== null && state.caseUi.tooths !== null) {
        const newLabWork = state.caseUi.labworks.filter((value) => {
          return action.payload !== value.labWorkId;
        });
        const newTooths = state.caseUi.tooths;
        newTooths.upper.map((t) => {
          if (t.labwork === action.payload) {
            t.type = 0;
            t.labwork = -1;
          }
        });
        newTooths.lower.map((t) => {
          if (t.labwork === action.payload) {
            t.type = 0;
            t.labwork = -1;
          }
        });
        if (action.payload === state.caseUi.selectedLabWork) {
          state.caseUi.selectedLabWork = newLabWork.length > 0 ? newLabWork[0].labWorkId : -1;
          state.caseUi.selectedTeeth = { teeth: -1, quarter: -1 };
        }
        state.caseUi.tooths = { lower: newTooths.lower, upper: newTooths.upper };
        state.caseUi.labworks = newLabWork;
        addChangesToStack(state, removeLabworkChange(action.payload));
      }
    },

    setCaseDetailSetExtras: (state, action: PayloadAction<Array<Extra>>) => {
      if (
        state.caseUi !== null &&
        state.caseUi.labworks !== null &&
        state.caseUi.selectedLabWork !== null
      ) {
        const labWorkIndex = state.caseUi.labworks.findIndex((value) => {
          return state.caseUi !== null && state.caseUi.selectedLabWork === value.labWorkId;
        });
        if (labWorkIndex > -1) {
          state.caseUi.labworks[labWorkIndex].extras = action.payload;
          state.caseUi.selectedLabWork !== null &&
            addChangesToStack(
              state,
              createExtrasChange(state.caseUi.selectedLabWork, action.payload),
            );
        }
      }
    },

    setCaseDetailSetToothExtras: (state, action: PayloadAction<Array<Extra>>) => {
      if (
        state.caseUi !== null &&
        state.caseUi.selectedTeeth !== null &&
        state.caseUi.tooths !== null
      ) {
        const tooth = [...state.caseUi.tooths.upper, ...state.caseUi.tooths.lower].find((t) => {
          return (
            state.caseUi !== null &&
            state.caseUi.selectedTeeth !== null &&
            state.caseUi.selectedTeeth.teeth === t.teethp &&
            state.caseUi.selectedTeeth.quarter === t.quarter
          );
        });
        if (tooth) {
          tooth.ext = action.payload;
          addChangesToStack(
            state,
            createToothExtrasChange(tooth.labwork, tooth.quarter, tooth.teethp, action.payload),
          );
        }
      }
    },

    setCaseDetailUpdatePhases: (
      state,
      action: PayloadAction<{
        labworkId: number;
        templateId: number;
        phases: WorkPhaseGroup[] | null;
      }>,
    ) => {
      if (state.caseUi !== null && state.caseUi.labworks !== null) {
        const labWorkIndex = state.caseUi.labworks?.findIndex((value) => {
          return action.payload.labworkId === value.labWorkId;
        });
        if (labWorkIndex !== undefined && labWorkIndex > -1) {
          let phases;
          if (action?.payload?.phases === null) {
            phases = [];
          } else {
            phases = preparePhasesForUi(action?.payload?.phases || [], true, []) || [];
          }
          state.caseUi.labworks[labWorkIndex].editedPhases = phases;
          state.caseUi.labworks[labWorkIndex].phases = action?.payload?.phases;
          addChangesToStack(state, createPhasesChange(action.payload.labworkId, phases || []));
        }
      }
    },

    setCaseDetailInsertPhaseToPosition: (
      state,
      action: PayloadAction<{
        labworkId: number;
        phase: PhaseUiModel;
        phaseOrder: number;
        workers?: Array<WorkerData>;
      }>,
    ) => {
      if (state.caseUi !== null && state.caseUi.labworks !== null) {
        const labWorkIndex = state.caseUi.labworks?.findIndex((value) => {
          return action.payload.labworkId === value.labWorkId;
        });
        if (labWorkIndex !== undefined && labWorkIndex > -1) {
          const newPhaseList = [
            ...(state.caseUi.labworks[labWorkIndex].phases?.slice(0, action.payload.phaseOrder) ||
              []),
            WorkPhaseGroup.newInstanceFromPhaseUimodel(action.payload.phase),
            ...(state.caseUi.labworks[labWorkIndex].phases?.slice(action.payload.phaseOrder) || []),
          ];
          // rewrite local cache indexes --> NEED to use the update from the BE
          newPhaseList.forEach((value, index) => {
            value.order = index;
          });
          state.caseUi.labworks[labWorkIndex].editedPhases =
            preparePhasesForUi(newPhaseList || [], true, action.payload.workers || []) || [];
          state.caseUi.labworks[labWorkIndex].phases = newPhaseList || [];
          addChangesToStack(
            state,
            createInsertPhaseToPosition(
              action.payload.labworkId,
              action.payload.phaseOrder,
              action.payload.phase,
            ),
          );
        }
      }
    },

    setCaseDetailInsertDateToPosition: (
      state,
      action: PayloadAction<{
        labWorkId: number;
        phaseOrder: number;
        timestamp: number;
        workers?: Array<WorkerData>;
      }>,
    ) => {
      if (state.caseUi !== null && state.caseUi.labworks !== null) {
        const labWorkIndex = state.caseUi.labworks?.findIndex((value) => {
          return action.payload.labWorkId === value.labWorkId;
        });
        if (labWorkIndex !== undefined && labWorkIndex > -1) {
          const newPhaseList = [
            ...(state.caseUi.labworks[labWorkIndex].phases?.slice(0, action.payload.phaseOrder) ||
              []),
            new WorkPhaseGroup(
              1,
              action.payload.phaseOrder,
              undefined,
              undefined,
              undefined,
              undefined,
              action.payload.timestamp,
            ),
            ...(state.caseUi.labworks[labWorkIndex].phases?.slice(action.payload.phaseOrder) || []),
          ];
          // rewrite local cache indexes --> NEED to use the update from the BE
          newPhaseList.forEach((value, index) => {
            value.order = index;
          });
          const newPhaseUiModelList =
            preparePhasesForUi(newPhaseList || [], true, action.payload.workers || []) || [];
          state.caseUi.labworks[labWorkIndex].editedPhases = newPhaseUiModelList;
          state.caseUi.labworks[labWorkIndex].phases = newPhaseList || [];
          addChangesToStack(
            state,
            createInsertPhaseToPosition(
              action.payload.labWorkId,
              action.payload.phaseOrder,
              newPhaseUiModelList[action.payload.phaseOrder],
            ),
          );
        }
      }
    },

    setCaseDetailInsertTemplateToPosition: (
      state,
      action: PayloadAction<{
        labWorkId: number;
        template: Template;
        phaseOrder: number;
        workers?: Array<WorkerData>;
      }>,
    ) => {
      if (state.caseUi !== null && state.caseUi.labworks !== null) {
        const labWorkIndex = state.caseUi.labworks?.findIndex((value) => {
          return action.payload.labWorkId === value.labWorkId;
        });
        if (labWorkIndex !== undefined && labWorkIndex > -1) {
          const newPhaseList = state.caseUi.labworks[labWorkIndex].phases || [];
          newPhaseList.splice(
            action.payload.phaseOrder,
            0,
            ...(action.payload.template.phases || []),
          );
          // rewrite local cache indexes --> NEED to use the update from the BE
          newPhaseList.forEach((value, index) => {
            value.order = index;
          });
          const newPhaseUiModelList =
            preparePhasesForUi(newPhaseList || [], true, action.payload.workers || []) || [];
          state.caseUi.labworks[labWorkIndex].editedPhases = newPhaseUiModelList;
          state.caseUi.labworks[labWorkIndex].phases = newPhaseList || [];
          addChangesToStack(
            state,
            createInsertTemplateToPosition(
              action.payload.labWorkId,
              action.payload.phaseOrder,
              newPhaseUiModelList.slice(
                action.payload.phaseOrder,
                action.payload.phaseOrder +
                  (action.payload.template.phases?.length || action.payload.phaseOrder),
              ),
            ),
          );
        }
      }
    },

    setCaseDetailRemovePhaseFromPosition: (
      state,
      action: PayloadAction<{ labworkId: number; phaseOrder: number; workers: WorkerData[] }>,
    ) => {
      if (state.caseUi !== null && state.caseUi.labworks !== null) {
        const labWorkIndex = state.caseUi.labworks?.findIndex((value) => {
          return action.payload.labworkId === value.labWorkId;
        });
        if (labWorkIndex !== undefined && labWorkIndex > -1) {
          const p =
            state.caseUi.labworks[labWorkIndex].phases?.filter(
              (v) => v.order !== action.payload.phaseOrder,
            ) || [];
          const phases = preparePhasesForUi(p || [], true, action.payload.workers || []) || [];
          state.caseUi.labworks[labWorkIndex].editedPhases = phases;
          state.caseUi.labworks[labWorkIndex].phases = p || [];
          addChangesToStack(
            state,
            createDeletePhasesChange(action.payload.labworkId, action.payload.phaseOrder),
          );
        }
      }
    },

    setCaseDetailStatus: (state, action: PayloadAction<number>) => {
      if (state.caseUi !== null) {
        state.caseUi.status = action.payload;
        state.caseUi.workSheetEditable = state.caseUi.status === DRAFT;
        addChangesToStack(state, createStatusChange(action.payload));
      }
    },

    setCaseDetailPhaseAssignee: (
      state,
      action: PayloadAction<{
        labworkId: number;
        phaseId: number;
        phaseOrder: number;
        assigneeId: number;
        phaseStatus: number;
        workers: WorkerData[];
      }>,
    ) => {
      if (state.caseUi !== null && state.caseUi.labworks !== null) {
        const labWorkIndex = state.caseUi.labworks.findIndex((value) => {
          return action.payload.labworkId === value.labWorkId;
        });
        if (labWorkIndex > -1) {
          const phaseIndex = state.caseUi.labworks[labWorkIndex]!.phases?.findIndex((value) => {
            return action.payload.phaseId === value.id && action.payload.phaseOrder === value.order;
          });
          if (phaseIndex !== undefined && phaseIndex > -1) {
            state.caseUi.labworks[labWorkIndex].phases![phaseIndex].assigneeId =
              action.payload.assigneeId;
            if (
              state.caseUi.labworks[labWorkIndex].phases![phaseIndex].status !==
              action.payload.phaseStatus
            ) {
              state.needStatusChangeNoti = true;
            }
            state.caseUi.labworks[labWorkIndex].phases![phaseIndex].status =
              action.payload.phaseStatus;
            state.caseUi.labworks[labWorkIndex].editedPhases =
              preparePhasesForUi(
                state.caseUi.labworks[labWorkIndex].phases!,
                true,
                action.payload.workers,
              ) || [];
            console.log('LAZAR: action.payload.phaseStatus ' + action.payload.phaseOrder);

            addChangesToStack(
              state,
              createPhaseStatusAndAssignee(
                action.payload.labworkId,
                action.payload.phaseId,
                action.payload.phaseOrder,
                action.payload.assigneeId,
                action.payload.phaseStatus,
              ),
            );
          }
        }
      }
    },

    caseDetailUploadStarted: (state) => {
      state.loading = true;
    },

    caseDetailUploadFinished: (state, action: PayloadAction<number>) => {
      clearChangesBefore(state, action.payload);
      state.loading = false;
    },

    resetCaseDetail: (state) => {
      state.caseUi = null;
      state.revision = 0;
      state.stackOfChanges = [];
    },
    resetStatusNotiState: (state) => {
      state.needStatusChangeNoti = false;
    },
  },
});

function createToothChange(
  labworkId: number | null,
  tooths: Tooths,
): Array<Array<number | string> | Array<Array<number | Array<number>>>> {
  const newChange: Array<number | string> = [];

  newChange.push(updateType.UPDATE_TOOTH);
  labworkId !== null && newChange.push(labworkId);

  const extrasChange: Array<Array<number | Array<number>>> = [];

  [...tooths.lower, ...tooths.upper]
    .filter((v) => {
      return v.labwork === labworkId;
    })
    .forEach((t) => {
      newChange.push(t.quarter * 10 + t.teethp);
      newChange.push(t.type);
      newChange.push(t.leftConnectionType);

      if (t.ext.length > 0) {
        t.ext.forEach((e) => {
          const extraChange: Array<number | Array<number>> = [];
          extraChange.push(updateType.ADD_TOOTH_EXTENSIONS);
          labworkId !== null && extraChange.push(labworkId);
          extraChange.push(t.quarter * 10 + t.teethp);
          if (e.kind === 'Simple') {
            if (e.active) {
              extraChange.push([e.id, e.groupId]);
            }
          } else if (e.kind === 'Selectable') {
            const ex = e.values
              .filter((filter) => filter.id !== -100)
              .find((v) => {
                return v.selected;
              });
            if (ex) {
              extraChange.push([ex.id, e.id]);
            }
          }
          extraChange.length > 3 && extrasChange.push(extraChange);
        });
      }
    });

  const teethIds: any = [];
  extrasChange.forEach((c) => {
    const elementExists = teethIds.includes(c[2]);
    if (!elementExists) teethIds.push(c[2]);
  });

  const mergeExtentions: Array<Array<number | Array<number>>> = [];
  teethIds.forEach((teethId) => {
    const list = extrasChange.filter((e) => e[2] === teethId);
    let action;
    let index;
    let teeth;
    let extentions: number[] = [];
    list.forEach((e) => {
      action = e[0];
      index = e[1];
      teeth = e[2];
      if (Array.isArray(e[3])) {
        extentions = [...extentions, ...e[3]];
      } else {
        extentions = [...extentions, e[3]];
      }
    });
    mergeExtentions.push([action, index, teeth, extentions]);
  });
  return [newChange, mergeExtentions];
}

function createLabworkChange(labwork: DetailsLabWork): Array<number | string | any> {
  const newChange: Array<number | string | any> = [];

  if (labwork.workType !== null) {
    newChange.push(updateType.ADD_LAB_WORK);
    newChange.push(labwork.labWorkId);
    newChange.push('');
    newChange.push(labwork.workType.id);
    newChange.push(null); // phases

    const workTypeCategory: number[] = [];
    labwork.workType.category.forEach((v) => {
      workTypeCategory.push(v.id);
    });
    if (labwork.workType.selectedCategory > -1 && labwork.workType.categoryId > -1) {
      workTypeCategory.push(labwork.workType.selectedCategory);
      workTypeCategory.push(labwork.workType.categoryId);
    }
    newChange.push(workTypeCategory);
  }

  return newChange;
}

function removeLabworkChange(labworkId: number): Array<number | string | any> {
  const newChange: Array<number | string | any> = [];
  newChange.push(updateType.REMOVE_LAB_WORK);
  newChange.push(labworkId);
  return newChange;
}

function createExtrasChange(labworkId: number, extras: Array<Extra>): Array<number | string | any> {
  const newChange: Array<number | string | any> = [];
  newChange.push(updateType.ADD_LAB_WORK_EXTENSIONS);
  newChange.push(labworkId);

  const extrasChange: number[] = [];

  extras.forEach((t) => {
    if (t.kind === 'Simple') {
      if (t.active) {
        extrasChange.push(t.id);
        extrasChange.push(t.groupId);
      }
    } else if (t.kind === 'Selectable') {
      const ex = t.values
        .filter((filter) => filter.id !== -100)
        .find((v) => {
          return v.selected;
        });
      if (ex) {
        extrasChange.push(ex.id);
        extrasChange.push(t.id);
      }
    }
  });
  newChange.push(extrasChange);

  return newChange;
}

function createToothExtrasChange(
  labworkId: number,
  quarter: number,
  toothp: number,
  extras: Array<Extra>,
): Array<number | string | any> {
  const newChange: Array<number | string | any> = [];
  newChange.push(updateType.ADD_TOOTH_EXTENSIONS);
  newChange.push(labworkId);
  newChange.push(quarter * 10 + toothp);

  const extrasChange: number[] = [];

  extras.forEach((t) => {
    if (t.kind === 'Simple') {
      if (t.active) {
        extrasChange.push(t.id);
        extrasChange.push(t.groupId);
      }
    } else if (t.kind === 'Selectable') {
      const ex = t.values.find((v) => {
        return v.selected && v.id !== -100;
      });
      if (ex) {
        extrasChange.push(ex.id);
        extrasChange.push(t.id);
      }
    }
  });
  newChange.push(extrasChange);

  return newChange;
}

function createPhasesChange(
  labworkId: number,
  phases: PhaseUiModel[],
): Array<number | string | any> {
  const newChange: Array<number | string | any> = [];
  newChange.push(updateType.UPDATE_ALL_PHASE);
  newChange.push(labworkId);

  phases.forEach((p) => {
    newChange.push(0);
    newChange.push(p.kind === 'Date' ? p.date : p.id);
  });

  return newChange;
}

function createDeletePhasesChange(
  labworkId: number,
  phaseOrder: number,
): Array<number | string | any> {
  const newChange: Array<number | string | any> = [];
  newChange.push(updateType.DELETE_PHASE);
  newChange.push(labworkId);
  newChange.push(phaseOrder);
  return newChange;
}

function createClientChange(id: number, clientCompanyId: number): Array<number | string | any> {
  const newChange: Array<number | string | any> = [];
  newChange.push(updateType.CLIENT);
  newChange.push(id);
  newChange.push(clientCompanyId);
  return newChange;
}

function createPatientNameChange(name?: string): Array<number | string | any> {
  const newChange: Array<number | string | any> = [];
  newChange.push(updateType.PATIENT);
  newChange.push(name);
  newChange.push(-1);
  newChange.push(-1);
  return newChange;
}

function createPatientAgeChange(age?: number): Array<number | string | any> {
  const newChange: Array<number | string | any> = [];
  newChange.push(updateType.PATIENT);
  newChange.push('');
  newChange.push(age);
  newChange.push(-1);
  return newChange;
}

function createPatientGenderChange(gender?: Gender['id']): Array<number | string | any> {
  const newChange: Array<number | string | any> = [];
  newChange.push(updateType.PATIENT);
  newChange.push('');
  newChange.push(-1);
  newChange.push(gender);
  return newChange;
}

function createStatusChange(status: number): Array<number | string | any> {
  const newChange: Array<number | string | any> = [];
  newChange.push(updateType.UPDATE_STATUS);
  newChange.push(status);
  return newChange;
}

function createPhaseStatusAndAssignee(
  labWorkId: number,
  phaseId: number,
  phaseOrder: number,
  assigneeId: number,
  status: number,
): Array<number | string | any> {
  const newChange: Array<number | string | any> = [];
  newChange.push(updateType.UPDATE_PHASE_INFO);
  newChange.push(labWorkId);
  newChange.push(phaseId);
  newChange.push(phaseOrder);
  newChange.push(assigneeId);
  newChange.push(status);
  return newChange;
}

function createInsertPhaseToPosition(labWorkId: number, position: number, phase: PhaseUiModel) {
  const newChange: Array<number> = [];
  newChange.push(updateType.INSERT_PHASE_TO_POSITION);
  newChange.push(labWorkId);
  newChange.push(position);
  if (phase.kind === 'Date') {
    newChange.push(phaseType.PHASE_TYPE_TIME);
    newChange.push(phase.date);
  } else {
    newChange.push(phaseType.PHASE_TYPE_PHASE);
    newChange.push(phase.id);
  }
  return newChange;
}

function createInsertTemplateToPosition(
  labWorkId: number,
  position: number,
  phases: Array<PhaseUiModel>,
) {
  const newChange: Array<number> = [];
  newChange.push(updateType.INSERT_PHASE_TO_POSITION);
  newChange.push(labWorkId);
  newChange.push(position);
  phases.forEach((phase) => {
    if (phase.kind === 'Date') {
      newChange.push(phaseType.PHASE_TYPE_TIME);
      newChange.push(phase.date);
    } else {
      newChange.push(phaseType.PHASE_TYPE_PHASE);
      newChange.push(phase.id);
    }
  });
  return newChange;
}

const preparePhasesForUi = (
  phases: Array<WorkPhaseGroup>,
  assignable: boolean,
  workers: Array<WorkerData>,
): Array<PhaseUiModel> => {
  return mapPhaseUiModelsFromWorkPhaseGroups(phases, assignable, workers);
};

export default caseReducer.reducer;
export const {
  setCaseDetail,
  changeClient,
  changePatientName,
  changePatientAge,
  changePatientGender,
  setCaseDetailSelectedTooths,
  setCaseDetailTooths,
  setCaseDetailSelectedLabwork,
  setCaseDetailLabWork,
  setCaseDetailRemoveLabWork,
  setCaseDetailSetExtras,
  setCaseDetailSetToothExtras,
  setCaseDetailUpdatePhases,
  setCaseDetailInsertDateToPosition,
  setCaseDetailInsertPhaseToPosition,
  setCaseDetailInsertTemplateToPosition,
  setCaseDetailRemovePhaseFromPosition,
  caseDetailUploadStarted,
  caseDetailUploadFinished,
  setCaseDetailStatus,
  setCaseDetailPhaseAssignee,
  resetCaseDetail,
  resetStatusNotiState,
} = caseReducer.actions;
