export const LANGUAGE_KEYS = {
  TEST: 'TEST',
  MY_PROFILE: 'MY_PROFILE',
  MY_LABORATORIES: 'MY_LABORATORIES',
  ALERT_UPDATE_COMPANY_DATA_SUCCESS: 'ALERT_UPDATE_COMPANY_DATA_SUCCESS',
  ALERT_FAILED_GENERIC: 'ALERT_FAILED_GENERIC',
  AUTOSAVE_FAILED: 'AUTOSAVE_FAILED',
  DASHBOARD: 'DASHBOARD',
  DASHBOARD_FILTER_ALL: 'DASHBOARD_FILTER_ALL',
  DASHBOARD_FILTER_ACTIVE: 'DASHBOARD_FILTER_ACTIVE',
  DASHBOARD_FILTER_DRAFTS: 'DASHBOARD_FILTER_DRAFTS',
  DASHBOARD_SEARCH_BOARD_PLACEHOLDER: 'DASHBOARD_SEARCH_BOARD_PLACEHOLDER',
  NEW_CASE: 'NEW_CASE',
  DASHBOAR_LABWORKS: 'LABWORKS',
  GENERIC_SEE_MORE: 'SEE_MORE',
  GENERIC_SELECTED_PAGE_EMPTY_TITLE: 'GENERIC_SELECTED_PAGE_EMPTY_TITLE',
  GENERIC_SELECTED_PAGE_EMPTY_BODY: 'GENERIC_SELECTED_PAGE_EMPTY_BODY',

  PRINT_WITH_QR: 'PRINT_WITH_QR',
  REPORTS: 'REPORTS',
  CHAT: 'CHAT',
  CASE: 'CASE',
  ADD_CLIENT_NAME: 'ADD_CLIENT_NAME',
  ADD_PATIENT_NAME: 'ADD_PATIENT_NAME',
  FILL_AGE: 'FILL_AGE',
  GENDER: 'GENDER',
  MALE: 'MALE',
  FEMALE: 'FEMALE',
  OTHER: 'OTHER',
  NEUTRAL: 'NEUTRAL',
  DELIVERY_DATE: 'DELIVERY_DATE',
  DELIVERY_TIME: 'DELIVERY_TIME',
  CLICK_TO_SET: 'CLICK_TO_SET',
  SELECT_YOUR_WORK_TYPE: 'SELECT_YOUR_WORK_TYPE',
  FIRST_SET_YOUR_WORK: 'FIRST_SET_YOUR_WORK',
  PENDING_LABWORK: 'PENDING_LABWORK',
  FIRST_SELECT_A_WORK: 'FIRST_SELECT_A_WORK',
  THIS_WORK_ORDER_IS_ON_DRAFT: 'THIS_WORK_ORDER_IS_ON_DRAFT',
  ADD_NEW: 'ADD_NEW',
  SELECT_WORKTYPE: 'SELECT_WORKTYPE',
  ADD_EXTRAS: 'ADD_EXTRAS',
  TOOTH: 'TOOTH',
  CREATE_NEW: 'CREATE_NEW',
  ADD_NEW_PHASE: 'ADD_NEW_PHASE',
  DESCRIPTION: 'DESCRIPTION',
  CLICK_TO_ADD_DESCRIPTION: 'CLICK_TO_ADD_DESCRIPTION',
  DROP_YOUR_FILES: 'DROP_YOUR_FILES',
  SUPPORT_FILES: 'SUPPORT_FILES',
  PUBLISH: 'PUBLISH',
  ASSIGN: 'ASSIGN',
  START: 'START',
  START_PENDING: 'START_PENDING',
  DONE: 'DONE',
  CLUMSY: 'CLUMSY',
  INATTENTIVE: 'INATTENTIVE',
  INSUFFICIENT_KNOWLEDGE: 'INSUFFICIENT_KNOWLEDGE',
  ADD_NEW_ITEM: 'ADD_NEW_ITEM',
  NEW_PHASE: 'NEW_PHASE',
  DATE_AND_TIME: 'DATE_AND_TIME',
  CHOOSE_FROM_TEMPLATE: 'CHOOSE_FROM_TEMPLATE',
  PHASE_NAME: 'PHASE_NAME',
  CANCEL: 'CANCEL',
  CREATE: 'CREATE',
  REJECT: 'REJECT',
  SAVE: 'SAVE',
  SET_DUE_DATE_AND_TIME: 'SET_DUE_DATE_AND_TIME',
  JANUARY: 'JANUARY',
  FEBRUARY: 'FEBRUARY',
  MARCH: 'MARCH',
  APRIL: 'APRIL',
  MAY: 'MAY',
  JUNE: 'JUNE',
  JULY: 'JULY',
  AUGUST: 'AUGUST',
  SEPTEMBER: 'SEPTEMBER',
  OCTOBER: 'OCTOBER',
  NOVEMBER: 'NOVEMBER',
  DECEMBER: 'DECEMBER',
  SUN: 'SUN',
  MON: 'MON',
  TUE: 'TUE',
  WED: 'WED',
  THU: 'THU',
  FRI: 'FRI',
  SAT: 'SAT',
  TIME: 'TIME',
  DATE: 'DATE',
  SET_TIME: 'SET_TIME',
  TEMPLATE_NAME: 'TEMPLATE_NAME',

  ID: 'ID',
  CLIENT: 'CLIENT',
  CABINET: 'CABINET',
  COLLABORATORS: 'COLLABORATORS',
  MENU_COLABBRATORS: 'MENU_COLABBRATORS',
  CREATION: 'CREATION',
  FINISH: 'FINISH',
  CASE_STATE: 'CASE_STATE',
  INVOICE_STATE: 'INVOICE_STATE',
  SEARCH_FILTER_RESULTS: 'SEARCH_FILTER_RESULTS',
  PATIENT: 'PATIENT',
  COLLABORATOR: 'COLLABORATOR',
  DATE_FROM: 'DATE_FROM',
  DATE_TO: 'DATE_TO',
  STATE: 'STATE',
  INVOICE: 'INVOICE',
  CASES: 'CASES',
  DRAFT: 'DRAFT',
  ACTIVE: 'ACTIVE',
  INVOICED: 'INVOICED',
  CLOSED: 'CLOSED',
  NOT_INVOICED: 'NOT_INVOICED',
  PAYED: 'PAYED',
  FILTER_NO_RESULT_TITLE: 'FILTER_NO_RESULT_TITLE',
  FILTER_NO_RESULT_DESCRIPTION: 'FILTER_NO_RESULT_DESCRIPTION',
  OVERVIEW: 'OVERVIEW',
  EXPENSE: 'EXPENSE',
  PAY_OFF: 'PAY_OFF',
  TOTAL: 'TOTAL',
  TOTAL_AMOUNT: 'TOTAL_AMOUNT',
  GENERATE_INVOICE: 'GENERATE_INVOICE',
  ALL: 'ALL',
  SOLVED: 'SOLVED',
  UNSOLVED: 'UNSOLVED',
  ALERT_SOMETHING_WENT_WRONG: 'ALERT_SOMETHING_WENT_WRONG',
  ALERT_PAY_OFF_PHASE_SUCCESS: 'ALERT_PAY_OFF_PHASE_SUCCESS',
  ALERT_WITHDRAW_PAYMENT_IN_CASE_SUCCESS: 'ALERT_WITHDRAW_PAYMENT_IN_CASE_SUCCESS',
  ALERT_WITHDRAW_PAYMENT_SUCCESS: 'ALERT_WITHDRAW_PAYMENT_SUCCESS',
  ALERT_GENERATE_INVOICE_SUCCESS: 'ALERT_GENERATE_INVOICE_SUCCESS',
  ALERT_COMPANY_DATA_MISSING: 'ALERT_COMPANY_DATA_MISSING',
  CUMPARATOR: 'CUMPARATOR',
  SEDIU: 'SEDIU',
  PUNCT_DE_LUCRU: 'PUNCT_DE_LUCRU',
  CIF: 'CIF',
  NR_REG_COM: 'NR_REG_COM',
  CAPITAL_SOCIAL: 'CAPITAL_SOCIAL',
  NR: 'NR',
  CRT: 'CRT',
  DENUMIRE_PRODUSELOR_SERVICIILOR: 'DENUMIRE_PRODUSELOR_SERVICIILOR',
  UM: 'UM',
  CANTITATEA: 'CANTITATEA',
  CANTITATEA_SCURT: 'CANTITATEA_SCURT',
  PRET_UNITAR_LEI: 'PRET_UNITAR_LEI',
  PRET_UNITAR: 'PRET_UNITAR',
  VALOAREA: 'VALOAREA',
  LEI: 'LEI',
  BUC: 'BUC',
  TERMEN_DE_PLATA_FACTURA: 'TERMEN_DE_PLATA_FACTURA',
  FACTURA_CIRCULA: 'FACTURA_CIRCULA',
  DETALII_DE_PLATA: 'DETALII_DE_PLATA',
  CASH: 'CASH',
  BANCA: 'BANCA',
  TRANSFER_BANCAR: 'TRANSFER_BANCAR',
  SUBTOTAL: 'SUBTOTAL',
  SAVE_INVOICE: 'SAVE_INVOICE',
  ALERT_INVOICE_NR_EXISTS: 'ALERT_INVOICE_NR_EXISTS',
  ALERT_INVOICE_UPDATE_SUCCESS: 'ALERT_INVOICE_UPDATE_SUCCESS',
  INVOICE_PDF_TITLE: 'INVOICE_PDF_TITLE',
  BILLING_INVOICE: 'BILLING_INVOICE',
  INVOICE_NO: 'INVOICE_NO',
  COMPANY: 'COMPANY',
  CREATION_DATE: 'CREATION_DATE',
  PREVIEW: 'PREVIEW',
  PRINT: 'PRINT',
  MAIL: 'MAIL',
  DELETE: 'DELETE',
  CLOSE: 'CLOSE',
  REOPEN: 'REOPEN',
  DUPLICATE: 'DUPLICATE',
  UNPAID: 'UNPAID',
  PAID: 'PAID',
  ALERT_PAYMENT_SUCCESS: 'ALERT_PAYMENT_SUCCESS',
  WORKTYPES: 'WORKTYPES',
  WORKTYPES_NAME: 'WORKTYPES_NAME',
  EXTENSIONS: 'EXTENSIONS',
  TOOTH_TEETH: 'TOOTH_TEETH',
  TEMPLATES: 'TEMPLATES',
  TEETH: 'TEETH',
  EXTENSION_LABEL: 'EXTENSION_LABEL',
  TOOTH_LABEL: 'TOOTH_LABEL',
  TEMPLATES_LABEL: 'TEMPLATES_LABEL',
  EXTENSION_NAME: 'EXTENSION_NAME',
  WORKTYPE_COMPONENTS: 'WORKTYPE_COMPONENTS',
  TOOTHTYPE_COMPONENTS: 'TOOTHTYPE_COMPONENTS',
  PRICE: 'PRICE',
  ADD_NEW_TEMPLATE: 'ADD_NEW_TEMPLATE',
  SEARCH_BOARD: 'SEARCH_BOARD',
  PHASES: 'PHASES',
  PHASE: 'PHASE',
  EMPTY_TEMPLATE_NAME: 'EMPTY_TEMPLATE_NAME',
  CONNECTED_EXTENSION: 'CONNECTED_EXTENSION',
  ADDED_PHASES: 'ADDED_PHASES',
  ADD_NEW_EXTENTION: 'ADD_NEW_EXTENTION',
  DRAG_ELEMENT_TO_CONNECT_PHASES: 'DRAG_ELEMENT_TO_CONNECT_PHASES',
  DRAG_ELEMENT_TO_CONNECT_EXTENSION: 'DRAG_ELEMENT_TO_CONNECT_EXTENTION',
  SEARCH_PHASE: 'SEARCH_PHASE',
  SEARCH_EXTENTION: 'SEARCH_EXTENTION',
  CLEAR_ALL: 'CLEAR_ALL',
  ADD_NEW_COMPANY: 'ADD_NEW_COMPANY',
  EDIT_COMPANY_DATA: 'EDIT_COMPANY_DATA',
  ADD_NEW_CLIENT: 'ADD_NEW_CLIENT',
  ADD_NEW_WORKER: 'ADD_NEW_WORKER',
  COMPANIES: 'COMPANIES',
  CLIENTS: 'CLIENTS',
  WORKERS: 'WORKERS',
  COMPANY_NAME: 'COMPANY_NAME',
  BANK: 'BANK',
  COD_IBAN: 'COD_IBAN',
  CITY: 'CITY',
  ADDRESS: 'ADDRESS',
  COUNTY: 'COUNTY',
  COUNTRY: 'COUNTRY',
  EDIT: 'EDIT',
  RESEND_INVITE: 'RESEND_INVITE',
  SEND_INVITE: 'SEND_INVITE',
  SEARCH_FOR_COMPANY: 'SEARCH_FOR_COMPANY',
  EDIT_WORKER_DATA: 'EDIT_WORKER_DATA',
  EDIT_CLIENT_DATA: 'EDIT_CLIENT_DATA',
  USER_NAME: 'USER_NAME',
  EMAIL: 'EMAIL',
  PHONE: 'PHONE',
  NO_MORE_OPTION: 'NO_MORE_OPTION',
  NO_SELECTED_COMPANY: 'NO_SELECTED_COMPANY',
  SELECT_ROLE_TEXT: 'SELECT_ROLE_TEXT',
  NEED_EMAIL_FOR_INVITE: 'NEED_EMAIL_FOR_INVITE',
  INVALID_EMAIL_ADDRESS: 'INVALID_EMAIL_ADDRESS',
  INVALID_PHONE_NUMBER: 'INVALID_PHONE_NUMBER',
  EDIT_SEND: 'EDIT_SEND',
  CREATE_SEND: 'CREATE_SEND',
  SEND_EMAIL_INVITE_TO_JOIN_DENTECH: 'SEND_EMAIL_INVITE_TO_JOIN_DENTECH',
  ROLE: 'ROLE',
  ROLE_OTHER: 'ROLE_OTHER',
  ROLE_OWNER: 'ROLE_OWNER',
  ROLE_ADMIN: 'ROLE_ADMIN',
  ROLE_SUPERVISOR: 'ROLE_SUPERVISOR',
  ROLE_EMPLOYEE: 'ROLE_EMPLOYEE',
  ADD_LABORATORY_NAME: 'ADD_LABORATORY_NAME',
  DELETE_SUCCESSFULL: 'DELETE_SUCCESSFULL',
  VAT: 'VAT',
  PAGE_NOT_FOUND_TITLE: 'PAGE_NOT_FOUND_TITLE',
  PAGE_NOT_FOUND_BODY: 'PAGE_NOT_FOUND_BODY',
  PAGE_NOT_FOUND_SIGN_OUT_TEXT: 'PAGE_NOT_FOUND_SIGN_OUT_TEXT',
  CALENDAR: 'CALENDAR',
  FAQ: 'FAQ',
  SETTINGS: 'SETTINGS',
  PLANS: 'PLANS',
  COMPANY_DATA: 'COMPANY_DATA',
  GENERAL: 'GENERAL',
  NOTIFICATIONS: 'NOTIFICATIONS',
  PREFERENCES: 'PREFERENCES',
  MANAGE_ACOUNTS: 'MANAGE_ACOUNTS',
  LABS: 'LABS',
  LOGOUT: 'LOGOUT',
  LOG_OUT: 'LOG_OUT',
  UPGRADE_FOR_PREMIUM_FEATURES: 'UPGRADE_FOR_PREMIUM_FEATURES',
  VIEW_PLANS: 'VIEW_PLANS',
  CREATE_NEW_LAB: 'CREATE_NEW_LAB',
  EMPTY_LOBBY_TITLE: 'EMPTY_LOBBY_TITLE',
  EMPTY_LOBBY_BODY: 'EMPTY_LOBBY_BODY',
  DECLINE: 'DECLINE',
  ACCEPT: 'ACCEPT',
  ENTER: 'ENTER',
  INVITATION_CARD_BODY: 'INVITATION_CARD_BODY',
  CREATE_LABORATORY_TITLE: 'CREATE_LABORATORY_TITLE',
  CREATE_LABORATORY_DESCRIPTION: 'CREATE_LABORATORY_DESCRIPTION',
  CONTINUE: 'CONTINUE',
  START_FOR_FREE: 'START_FOR_FREE',
  PASSWORD: 'PASSWORD',
  CONFIRMATION: 'CONFIRMATION',
  EMAIL_ADDRESS: 'EMAIL_ADDRESS',
  AGREE_TO_JOIN_MAIL_LIST: 'AGREE_TO_JOIN_MAIL_LIST',
  BY_CLICK_YOU_AGREE: 'BY_CLICK_YOU_AGREE',
  TERMS_AND_CONDITIONS: 'TERMS_AND_CONDITIONS',
  PRIVACY_POLICY: 'PRIVACY_POLICY',
  ALREADY_HAVE_AN_ACCOUNT: 'ALREADY_HAVE_AN_ACCOUNT',
  HAVE_NO_ACCOUNT: 'HAVE_NO_ACCOUNT',
  LOG_IN: 'LOG_IN',
  LOGIN: 'LOGIN',
  CREATE_ACCOUNT: 'CREATE_ACCOUNT',
  CREATE_ONE: 'CREATE_ONE',
  ERROR_MESSAGE_EMAIL_PASSWROD_DO_NOT_MATCH: 'ERROR_MESSAGE_EMAIL_PASSWROD_DO_NOT_MATCH',
  ERROR_MESSAGE_ENTER_VALID_MAIL: 'ERROR_MESSAGE_ENTER_VALID_MAIL',
  ERROR_MESSAGE_THIS_EMAIL_IS_IN_USE: 'ERROR_MESSAGE_THIS_EMAIL_IS_IN_USE',
  CHECK_YOUR_INBOX: 'CHECK_YOUR_INBOX',
  CLICK_ON_THE_LINK: 'CLICK_ON_THE_LINK',
  CANT_FIND_MAIL_INBOX_SPAM: 'CANT_FIND_MAIL_INBOX_SPAM',
  CLICK_HERE: 'CLICK_HERE',
  TO_SEND_AGAIN: 'TO_SEND_AGAIN',
  WRONG_ADDRESS: 'WRONG_ADDRESS',
  LOG_OUT_TO_SIGN_IN_WITH_DIFFERENT: 'LOG_OUT_TO_SIGN_IN_WITH_DIFFERENT',
  RESET_YOUR_PASSWORD: 'RESET_YOUR_PASSWORD',
  SET_YOUR_PASSWORD: 'SET_YOUR_PASSWORD',
  PICK_A_PASSWORD: 'PICK_A_PASSWORD',
  SET_PASSWORD: 'SET_PASSWORD',
  ERROR_MESSAGE_PASSWROD_CONFIRMATION_DO_NOT_MATCH:
    'ERROR_MESSAGE_PASSWROD_CONFIRMATION_DO_NOT_MATCH',
  ALERT_RESET_PASSWORD_SUCCESSFULL: 'ALERT_RESET_PASSWORD_SUCCESSFULL',
  CHOOSE_LANGUAGE: 'CHOOSE_LANGUAGE',
  CHOOSE_LANGUAGE_DESCRIPTION: 'CHOOSE_LANGUAGE_DESCRIPTION',
  COULD_U_TELL_US_UR_NAME: 'COULD_U_TELL_US_UR_NAME',
  COULD_U_TELL_US_UR_NAME_DESCRIPTION: 'COULD_U_TELL_US_UR_NAME_DESCRIPTION',
  SELECT_COLOR_FOR_PROFILE: 'SELECT_COLOR_FOR_PROFILE',
  SELECT_COLOR_FOR_PROFILE_DESCRIPTION: 'SELECT_COLOR_FOR_PROFILE_DESCRIPTION',
  RESET_PASSWORD: 'RESET_PASSWORD',
  RESET_PASSWORD_PAGE_DESCRIPTION: 'RESET_PASSWORD_PAGE_DESCRIPTION',
  RESET_PASSWORD_FOLLOWUP_TEXT: 'RESET_PASSWORD_FOLLOWUP_TEXT',
  BACK_TO_LOGIN: 'BACK_TO_LOGIN',
  INVITATION_SENT: 'INVITATION_SENT',
  PROFILE_INFO: 'PROFILE_INFO',
  LANGUAGE: 'LANGUAGE',
  FIRST_NAME: 'FIRST_NAME',
  LAST_NAME: 'LAST_NAME',
  PHONE_NUMBER: 'PHONE_NUMBER',
  LANGUAGE_SELECT_TEXT: 'LANGUAGE_SELECT_TEXT',
  SELECT_COLOR_MODAL_TITLE: 'SELECT_COLOR_MODAL_TITLE',
  ADD_COLOR: 'ADD_COLOR',
  UPDATE_MY_PROFILE_SUCCESSFULL: 'UPDATE_MY_PROFILE_SUCCESSFULL',
  ALERT_RESET_PASSWORD_SEND_EMAIL_SUCCESSFULL: 'ALERT_RESET_PASSWORD_SEND_EMAIL_SUCCESSFULL',
  DELETE_CASAE_NOTIFICATION_SUCCESSFULL: 'DELETE_CASAE_NOTIFICATION_SUCCESSFULL',
  REOPEN_CASAE_NOTIFICATION_SUCCESSFULL: 'REOPEN_CASAE_NOTIFICATION_SUCCESSFULL',
  CLOSE_CASAE_NOTIFICATION_SUCCESSFULL: 'CLOSE_CASAE_NOTIFICATION_SUCCESSFULL',
  DELETE_CASAE_NOTIFICATION_FAILED: 'DELETE_CASAE_NOTIFICATION_FAILED',
  REOPEN_CASAE_NOTIFICATION_FAILED: 'REOPEN_CASAE_NOTIFICATION_FAILED',
  CLOSE_CASAE_NOTIFICATION_FAILED: 'CLOSE_CASAE_NOTIFICATION_FAILED',
  DELETE_LABWORK_TITLE: 'DELETE_LABWORK_TITLE',
  DELETE_LABWORK_DESCRIPT: 'DELETE_LABWORK_DESCRIPT',

  PACKAGE_TILE_BASIC: 'PACKAGE_TILE_BASIC',
  PACKAGE_TILE_PLUS: 'PACKAGE_TILE_PLUS',
  PACKAGE_TILE_PRO: 'PACKAGE_TILE_PRO',
  PACKAGE_SUBTILE_BASIC: 'PACKAGE_SUBTILE_BASIC',
  PACKAGE_SUBTILE_PLUS: 'PACKAGE_SUBTILE_PLUS',
  PACKAGE_SUBTILE_PRO: 'PACKAGE_SUBTILE_PRO',
  PACKIGE_PRICE: 'PACKIGE_PRICE',
  AFTERPRICE: 'AFTERPRICE',
  TRY_BUTTON: 'TRY_BUTTON',
  PRICE_BUTTON: 'PRICE_BUTTON',
  DOWNGRADE: 'DOWNGRADE',
  UPGRADE: 'UPGRADE',
  DURING_TRYAL: 'DURING_TRYAL',
  CANCEL_SUBSCRIPTION: 'CANCEL_SUBSCRIPTION',
  SUBSCRIPTION_PLAN: 'SUBSCRIPTION_PLAN',
  ACCSES_UNTIL: 'ACCSES_UNTIL',
  CANCEL_SUBSCRIPTION_DESCRIPTION: 'CANCEL_SUBSCRIPTION_DESCRIPTION',
  DOWNGRADE_SUBSCRIPTION: 'DOWNGRADE_SUBSCRIPTION',
  DOWNGRADE_SUBSCRIPTION_DESCRIPTION: 'DOWNGRADE_SUBSCRIPTION_DESCRIPTION',
  TALK_TO_US: 'TALK_TO_US',
  FEATURE_CASES: 'FEATURE_CASES',
  FEATURE_MOBILE: 'FEATURE_MOBILE',
  FEATURE_SUPORT: 'FEATURE_SUPORT',
  FEATURE_EMPLOYEE_MANAGEMENT: 'FEATURE_EMPLOYEE_MANAGEMENT',
  FEATURE_PERSONALIZED_WORKFLOW: 'FEATURE_PERSONALIZED_WORKFLOW',
  FEATURE_CLIENT_MANAGEMENT: 'FEATURE_CLIENT_MANAGEMENT',
  FEATURE_INVOICE: 'FEATURE_INVOICE',
  FEATURE_EMPLOYE_NUMBER: 'FEATURE_EMPLOYE_NUMBER',
  FEATURE_E_FACTURA: 'FEATURE_E_FACTURA',
  UPGRADE_SUBSCRIPTION: 'UPGRADE_SUBSCRIPTION',
  GO_TO_SETTINGS: 'GO_TO_SETTINGS',
  GO_TO_SETTINGS_DESCRIPTION: 'GO_TO_SETTINGS_DESCRIPTION',
  WANT_TO_DOWNGRADE: 'WANT_TO_DOWNGRADE',
  ACTIVEAZA_E_FACTURA: 'ACTIVEAZA_E_FACTURA',
  OBTINE_AUTORIZATIA: 'OBTINE_AUTORIZATIA',
  FEATURE_UNLIMITED_EMPLOYE: 'FEATURE_UNLIMITED_EMPLOYE',
  MENU_BILLING: 'MENU_BILLING',
  YOUR_PLAN: 'YOUR_PLAN',
  TRIAL_EXPIRE: 'TRIAL_EXPIRE',
  NEXT_BILLING_DATE: 'NEXT_BILLING_DATE',
  MANAGE_SUBSCRIPTION: 'MANAGE_SUBSCRIPTION',
  MEMBER_SINE: 'MEMBER_SINE',
  BACK: 'BACK',
  FEATURE_SOON_1: 'FEATURE_SOON_1',
  FEATURE_SOON_2: 'FEATURE_SOON_2',
  FEATURE_SOON_3: 'FEATURE_SOON_3',
  PACKIGE_SELECTION: 'PACKIGE_SELECTION',
  CANCEL_SUBSCRIPTION_BUTTON: 'CANCEL_SUBSCRIPTION_BUTTON',
  PACKAGE_DESCRIPTION_PRO: 'PACKAGE_DESCRIPTION_PRO',
  ACTIVATE_E_FACTURA: 'ACTIVATE_E_FACTURA',
  GET_AUTHORIZATION: 'GET_AUTHORIZATION',
  AUTHORIZATION_TITLE: 'AUTHORIZATION_TITLE',
  AUTHORIZATION_DESCRIPTION_1: 'AUTHORIZATION_DESCRIPTION_1',
  AUTHORIZATION_DESCRIPTION_2: 'AUTHORIZATION_DESCRIPTION_2',
  AUTHORIZATION_ACCOUNTABLE: 'AUTHORIZATION_ACCOUNTABLE',
  AUTHORIZATION_SELF: 'AUTHORIZATION_SELF',
  AUTHORIZATION_HELP: 'AUTHORIZATION_HELP',
  AUTHORIZATION_ACCOUNTABLE_TITLE: 'AUTHORIZATION_ACCOUNTABLE_TITLE',
  AUTHORIZATION_ACCOUNTABLE_DESCRIPTION_1: 'AUTHORIZATION_ACCOUNTABLE_DESCRIPTION_1',
  COPY_LINK: 'COPY_LINK',
  SEND_EMAIL: 'SEND_EMAIL',
  WAIT_2: 'WAIT_2',
  AUTHORIZATION_SUCCESSFULL: 'AUTHORIZATION_SUCCESSFULL',
  NOTHING_TO_DO: 'NOTHING_TO_DO',
  AUTHORIZATION_ERROR: 'AUTHORIZATION_ERROR',
  AUTHORIZATION_ERROR_SUBTITLE: 'AUTHORIZATION_ERROR_SUBTITLE',
  AUTHORIZATION_ERROR_DESCRIPTION: 'AUTHORIZATION_ERROR_DESCRIPTION',
  AUTHORIZATION_SUCCESFULL: 'AUTHORIZATION_SUCCESFULL',
  TRY_AGAIN: 'TRY_AGAIN',
  AUTHORIZATION_HOME: 'AUTHORIZATION_HOME',
  AUTHORIZATION_HOME_SUCCSESS: 'AUTHORIZATION_HOME_SUCCSESS',
  AUTHORIZATION_HOME_SUCCSESS_DESCRIPTION: 'AUTHORIZATION_HOME_SUCCSESS_DESCRIPTION',
  AUTHORIZATION_HOME_ERROR: 'AUTHORIZATION_HOME_ERROR',
  POSSIBLE_CAUSES_TITLE: 'POSSIBLE_CAUSES_TITLE',
  POSSIBLE_CAUSES_1: 'POSSIBLE_CAUSES_1',
  POSSIBLE_CAUSES_2: 'POSSIBLE_CAUSES_2',
  POSSIBLE_CAUSES_3: 'POSSIBLE_CAUSES_3',
  RETRY_AUTORIZATION_TITLE: 'RETRY_AUTORIZATION_TITLE',
  RETRY_AUTORIZATION_1: 'RETRY_AUTORIZATION_1',
  RETRY_AUTORIZATION_2: 'RETRY_AUTORIZATION_2',
  ABOUT_AUTHORIZATION_ERROR: 'ABOUT_AUTHORIZATION_ERROR',
  ABOUT_REGISTRATION_SPV: 'ABOUT_REGISTRATION_SPV',
  AUTHORIZATION_SPV: 'AUTHORIZATION_SPV',
  IBAN: 'IBAN',
  SELECT_DATE: 'SELECT_DATE',
  SELECT: 'SELECT',
  NO_WORTYPE: 'NO_WORTYPE',
  PLEASE_FILL: 'PLEASE_FILL',
  FURNIZOR: 'FURNIZOR',
  SEND_E_FACTURA: 'SEND_E_FACTURA',
  UPLOAD_INDEX: 'UPLOAD_INDEX',
  UPLOAD_INDEX_DATE: 'UPLOAD_INDEX_DATE',
  INVOICE_PROCESING: 'INVOICE_PROCESING',
  WAIT: 'WAIT',
  INVOICE_ERROR: 'INVOICE_ERROR',
  RESEND_INVOICE: 'RESEND_INVOICE',
  E_FACTURA_SEND_GOOD_RESPONSE: 'E_FACTURA_SEND_GOOD_RESPONSE',
  UPLOAD_SPV: 'UPLOAD_SPV',
  SEND: 'SEND',
  QUIT: 'QUIT',
  CONFIRM_DELETE_COMPANY_TEXT: 'CONFIRM_DELETE_COMPANY_TEXT',
  IDENTIFICATION_CODE_HINT_TEXT: 'IDENTIFICATION_CODE_HINT_TEXT',
  CONFIRM_DELETE_PHASES_TEXT: 'CONFIRM_DELETE_PHASES_TEXT',
  CONFIRM_EDIT_PHASES_TEXT: 'CONFIRM_EDIT_PHASES_TEXT',
  ACRYLIC_DENTURES: 'ACRYLIC_DENTURES',
  METALLIC_REINFORCEMENT: 'METALLIC_REINFORCEMENT',
  CR_CO_FRAME_SECUNDER_PART: 'CR_CO_FRAME_SECUNDER_PART',
  TITANUIUM_FRAME_SECUNDER_PART: 'TITANUIUM_FRAME_SECUNDER_PART',
  PEEK_FRAME_SECUNDER_PART: 'PEEK_FRAME_SECUNDER_PART',
  STUDY_MODEL: 'STUDY_MODEL',
  PRINTED_MODEL: 'PRINTED_MODEL',
  CASE_STUDY: 'CASE_STUDY',
  THREED_PROJECT_CHECK: 'THREED_PROJECT_CHECK',
  COMPOSITE_SET: 'COMPOSITE_SET',
  LAMINATED_COMPOSITE_GUM: 'LAMINATED_COMPOSITE_GUM',
  LAMINATED_ACRYL_GUM: 'LAMINATED_ACRYL_GUM',
  PALATINE_FOLDS: 'PALATINE_FOLDS',
  EXTRA_WAX_TRY_IN: 'EXTRA_WAX_TRY_IN',
  LOCATOR: 'LOCATOR',
  BAR: 'BAR',
  IMPLANT_PREPARATION: 'IMPLANT_PREPARATION',
  TI_BASE: 'TI_BASE',
  MULTI_UNIT: 'MULTI_UNIT',
  ROOT_PIN_WITH_OTCAP: 'ROOT_PIN_WITH_OTCAP',
  TELESCOPE: 'TELESCOPE',
  CLASP: 'CLASP',
  PRINTED_DENTURES: 'PRINTED_DENTURES',
  LAMINATED_COMPOSITE_GUM__1: 'LAMINATED_COMPOSITE_GUM__1',
  EXTRA_TRY_IN: 'EXTRA_TRY_IN',
  CR_CO_FRAME_SECUNDER_PART__1: 'CR_CO_FRAME_SECUNDER_PART__1',
  TITANUIUM_FRAME_SECUNDER_PART__1: 'TITANUIUM_FRAME_SECUNDER_PART__1',
  PEEK_FRAME_SECUNDER_PART__1: 'PEEK_FRAME_SECUNDER_PART__1',
  METALOCERAMIC: 'METALOCERAMIC',
  PATIAL_METALOCERAMIC: 'PATIAL_METALOCERAMIC',
  METALIC_CROWN: 'METALIC_CROWN',
  ZIRCONIA_MONOLITIC: 'ZIRCONIA_MONOLITIC',
  ZIRCONIA__CERAMIC: 'ZIRCONIA__CERAMIC',
  PARTIAL_ZIRCONIA__CERAMIC: 'PARTIAL_ZIRCONIA__CERAMIC',
  DIGITAL_WAXUP: 'DIGITAL_WAXUP',
  ANALOG_WAXUP: 'ANALOG_WAXUP',
  TRY_IN: 'TRY_IN',
  PMMA_TEMPORARY: 'PMMA_TEMPORARY',
  PRINTED_TEMPORARY: 'PRINTED_TEMPORARY',
  CERAMIC_GUM: 'CERAMIC_GUM',
  COMPOSITE_GUM: 'COMPOSITE_GUM',
  ROOT_PIN_CR_CO: 'ROOT_PIN_CR_CO',
  ROOT_PIN_PEEK: 'ROOT_PIN_PEEK',
  ROOT_PIN_ZIRCONIA: 'ROOT_PIN_ZIRCONIA',
  ANCHOR_SYSTEM: 'ANCHOR_SYSTEM',
  COMPOSITE_COVERED_SYSTEM: 'COMPOSITE_COVERED_SYSTEM',
  REPAIR: 'REPAIR',
  RELINING: 'RELINING',
  MATRICES_CHANGE: 'MATRICES_CHANGE',
  CUSTOM_ABUTMENT_ZIRCONIA: 'CUSTOM_ABUTMENT_ZIRCONIA',
  CUSTOM_ABUTMENT_TITANIUM: 'CUSTOM_ABUTMENT_TITANIUM',
  CUSTOM_ABUTMENT_COMPOZITE: 'CUSTOM_ABUTMENT_COMPOZITE',
  CUSTOM_ABUTMENT_CR_CO: 'CUSTOM_ABUTMENT_CR_CO',
  FOGPÓTLÁS: 'FOGPÓTLÁS',
  PRESSED_CERAMICS: 'PRESSED_CERAMICS',
  MOUNTING_IN_THE_ARTICULATOR_WITH_FACIAL_ARCH: 'MOUNTING_IN_THE_ARTICULATOR_WITH_FACIAL_ARCH',
  MILLED_CERAMIC_VENEER: 'MILLED_CERAMIC_VENEER',
  PRESSED_CERAMIC_VENEER: 'PRESSED_CERAMIC_VENEER',
  CERAMIC_VENEER_WITH_PLATINUM_ABUTMENT: 'CERAMIC_VENEER_WITH_PLATINUM_ABUTMENT',
  COMPOSITE_VENEER: 'COMPOSITE_VENEER',
  COMPOSITE_INLAY__ONLAY: 'COMPOSITE_INLAY__ONLAY',
  HYBRID_CERAMIC_INLAY__ONLAY: 'HYBRID_CERAMIC_INLAY__ONLAY',
  GOLD_INLAY__ONLAY: 'GOLD_INLAY__ONLAY',
  ZIRCONIA_INLAY__ONLAY: 'ZIRCONIA_INLAY__ONLAY',
  MONTREAL_BRIDGE: 'MONTREAL_BRIDGE',
  TORONTO_BRIDGE: 'TORONTO_BRIDGE',
  TIMBLE_BRIDGE: 'TIMBLE_BRIDGE',
  ECO_ALL_ON_X: 'ECO_ALL_ON_X',
  TEMPORARY_ALL_ON_X: 'TEMPORARY_ALL_ON_X',
  COMPOSITE_COVER: 'COMPOSITE_COVER',
  CERAMIC_COVER: 'CERAMIC_COVER',
  ACRYLIC_COVER: 'ACRYLIC_COVER',
  HYBRID_CERAMIC_COVER: 'HYBRID_CERAMIC_COVER',
  TITANIUM_STRUCTURE: 'TITANIUM_STRUCTURE',
  PEEK_STRUCTURE: 'PEEK_STRUCTURE',
  CR_CO_STRUCTURE: 'CR_CO_STRUCTURE',
  TIMBLE_CR_CO: 'TIMBLE_CR_CO',
  TIMBLE_TITAN: 'TIMBLE_TITAN',
  TIMBLE_PEEK: 'TIMBLE_PEEK',
  IBAR_TITANIUM: 'IBAR_TITANIUM',
  IBAR_CR_CO: 'IBAR_CR_CO',
  ZIRCONIA_MONOLITIC_SUPERSTRUCTURE: 'ZIRCONIA_MONOLITIC_SUPERSTRUCTURE',
  ZIRCONIA__CERAMIC_SUPERSTRUCTURE: 'ZIRCONIA__CERAMIC_SUPERSTRUCTURE',
  PMMA_SUPERSTRUCTURE: 'PMMA_SUPERSTRUCTURE',
  REPAIR_ALL_ON_X: 'REPAIR_ALL_ON_X',
  MAINTENANCE_ALL_ON_X: 'MAINTENANCE_ALL_ON_X',
  RELINING_ALL_ON_X: 'RELINING_ALL_ON_X',
  COMPOSITE_COVER__1: 'COMPOSITE_COVER__1',
  CERAMIC_COVER__1: 'CERAMIC_COVER__1',
  ACRYLIC_COVER__1: 'ACRYLIC_COVER__1',
  HYBRID_CERAMIC_COVER__1: 'HYBRID_CERAMIC_COVER__1',
  PRESSED_CERAMICS_COVER: 'PRESSED_CERAMICS_COVER',
  PROTECTION: 'PROTECTION',
  WHITENING: 'WHITENING',
  CONTENTION: 'CONTENTION',
  BRUXISM_PRINTED: 'BRUXISM_PRINTED',
  BRUXISM_MILLED: 'BRUXISM_MILLED',
  GINGIVECTOMY: 'GINGIVECTOMY',
  SURGICAL_GUIDE: 'SURGICAL_GUIDE',
  IMPLANT_SLEEVE: 'IMPLANT_SLEEVE',
  MODEL_DESIGN: 'MODEL_DESIGN',
  DENTURE_TRYIN_DESIGN: 'DENTURE_TRYIN_DESIGN',
  DENTURE_DESIGN: 'DENTURE_DESIGN',
  BITE_SPLINT_DESIGN: 'BITE_SPLINT_DESIGN',
  FRAME_DESIGN: 'FRAME_DESIGN',
  ANATOMICAL_DESIGN: 'ANATOMICAL_DESIGN',
  REDUCED_DESIGN: 'REDUCED_DESIGN',
  INDIVIDUAL_ABUTMENT_DESIGN: 'INDIVIDUAL_ABUTMENT_DESIGN',
  DIGITAL_ENGRAVING: 'DIGITAL_ENGRAVING',
  SUPERIOR_CR_CO_FRAME_WORK_PRINTING: 'SUPERIOR_CR_CO_FRAME_WORK_PRINTING',
  INFERIOR_CR_CO_FRAME_WORK_PRINTNG: 'INFERIOR_CR_CO_FRAME_WORK_PRINTNG',
  SUPERIOR_TITAN_FRAME_WORK_PRINTING: 'SUPERIOR_TITAN_FRAME_WORK_PRINTING',
  INFERIOR_TITAN_FRAME_WORK_PRINTING: 'INFERIOR_TITAN_FRAME_WORK_PRINTING',
  MONOLITHIC_ZIRCONIUM_MILLING: 'MONOLITHIC_ZIRCONIUM_MILLING',
  REDUCED_ZIRCONIUM_MILLING: 'REDUCED_ZIRCONIUM_MILLING',
  GLAZE: 'GLAZE',
  CR_CO_CROWN_PRINTING: 'CR_CO_CROWN_PRINTING',
  TITAN_CROWN_PRINTING: 'TITAN_CROWN_PRINTING',
  PMMA_MILLING: 'PMMA_MILLING',
  METALO_CERAMIC_COVERING: 'METALO_CERAMIC_COVERING',
  PARTIAL_METALO_CERAMI_COVERING: 'PARTIAL_METALO_CERAMI_COVERING',
  LAMINATED_CERAMIC_GUM: 'LAMINATED_CERAMIC_GUM',
  TITAN_BASE_GLUEING: 'TITAN_BASE_GLUEING',
  APPLYING_TEFLON: 'APPLYING_TEFLON',
  DEFAULT: 'DEFAULT',
  ZIRCONIUM_CERAMIC_COVERING: 'ZIRCONIUM_CERAMIC_COVERING',
  SKIN_CERAMIC_COVERING: 'SKIN_CERAMIC_COVERING',
  ACRYLIC_DENTURE_W: 'ACRYLIC_DENTURE_W',
  PRINTED_DENTURE: 'PRINTED_DENTURE',
  COMBINED_WORK: 'COMBINED_WORK',
  DENTURE_REPARATION: 'DENTURE_REPARATION',
  CROWN_PONTIC: 'CROWN_PONTIC',
  INLAY_ONLAY_FACET: 'INLAY_ONLAY_FACET',
  ALL_ON_X_INTEGRATED: 'ALL_ON_X_INTEGRATED',
  ALL_ON_X_ELEMENT: 'ALL_ON_X_ELEMENT',
  ALL_ON_X_MAINTENANCE: 'ALL_ON_X_MAINTENANCE',
  COLORS_TEMPLATES: 'COLORS_TEMPLATES',
  DESIGN: 'DESIGN',
  MILLING_CENTER: 'MILLING_CENTER',
  CERAMSIT: 'CERAMSIT',
  FULL_DENTURE_REPARATION: 'FULL_DENTURE_REPARATION',
  ABUTMENT_WITH_CROWN: 'ABUTMENT_WITH_CROWN',
  CROWN: 'CROWN',
  DENTURE: 'DENTURE',
  IMPLANT_WITH_CROWN: 'IMPLANT_WITH_CROWN',
  ROOT: 'ROOT',
  DENTURE_WITH_ROOT: 'DENTURE_WITH_ROOT',
  CROWN_WITH_ROOT: 'CROWN_WITH_ROOT',
  BAR_ATTACHMENT_WITH_IMPLANT: 'BAR_ATTACHMENT_WITH_IMPLANT',
  BAR_ATTACHMENT: 'BAR_ATTACHMENT',
  TELESCOPE_TEETH_TYPE: 'TELESCOPE_TEETH_TYPE',
  DENTURE_WITH_IMPLANT: 'DENTURE_WITH_IMPLANT',
  BITE_SPLINT: 'BITE_SPLINT',
  HEALTHY_TEETH: 'HEALTHY_TEETH',
  CREATE_NEW_LAB_ERROR: 'CREATE_NEW_LAB_ERROR',
  EXPIRED_PAYMANT_TITLE: 'EXPIRED_PAYMANT_TITLE',
  EXPIRED_PAYMANT_DESCRIPTION: 'EXPIRED_PAYMANT_DESCRIPTION',
  EXPIRED_PAYMANT_DESCRIPTION_EMPLOYEE: 'EXPIRED_PAYMANT_DESCRIPTION_EMPLOYEE',
  EXPIRED_PAYMANT_BUTTOM: 'EXPIRED_PAYMANT_BUTTOM',
  NOT_NOW: 'NOT_NOW',
  CHANGED_PHASE_STATUS: 'CHANGED_PHASE_STATUS',
};
