import React from 'react';
import { Text, TextColor, TextType } from '../components/dsm/Text';
import { LabWorkCardComponent } from './LabWorkCardComponent';
import styled from 'styled-components';

export const DashboardPlaceholderCardComponent = () => {
  return (
    <DashboardCardContainer>
      <ContentContainer>
        <FirstRowContainer>
          <NameContainer className={'emptyDashboardPlaceholderField'}></NameContainer>
          <IDContainer>
            <Text type={TextType.BODY_2}></Text>
          </IDContainer>
        </FirstRowContainer>
        <SecondRowContainer>
          <PatientNameConatiner className={'emptyDashboardPlaceholderField'}>
            <Text type={TextType.ROBOTO_400_10_14}></Text>
          </PatientNameConatiner>
        </SecondRowContainer>
      </ContentContainer>
      <LabWorksContentContainer>{createLabWorkItem()}</LabWorksContentContainer>
    </DashboardCardContainer>
  );
};

const getEmptyProgresView = () => {
  const emptyProgres: any[] = [];
  for (let i = 0; i < 9; i++) {
    emptyProgres.push(<ProgressIcon key={i} className='emptyDashboardPlaceholderField' />);
  }
  return emptyProgres;
};

function createLabWorkItem() {
  return (
    <LabWorksContentContainer>
      <LabWorkCardComponent
        labWork={null}
        time={null}
        leftEdge={false}
        rightEdge={true}
        className='emptyDashboardPlaceholderField'
      />
      <ProgressContainer>{getEmptyProgresView()}</ProgressContainer>
      <SeeMoreText
        className='emptyDashboardPlaceholderField'
        type={TextType.ROBOTO_400_10_14}
        clr={TextColor.PRIMARY}
      ></SeeMoreText>
    </LabWorksContentContainer>
  );
}

const DashboardCardContainer = styled.div`
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  padding: 14px 14px 10px 14px;
  background: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 3px;
  display: flex;
  gap: 16px;
  min-height: 184px;
  @media (max-width: 600px) {
    flex: 0 0 100%;
    max-width: 100%;
  }

  @media (min-width: 601px) {
    flex: 0 0 calc(50% - ((20 / 2) * 1px));
    max-width: calc(50% - ((20 / 2) * 1px));
  }

  @media (min-width: 961px) {
    flex: 0 0 calc(33.333% - ((20 / 3) * 2px));
    max-width: calc(33.333% - ((20 / 3) * 2px));
  }

  @media (min-width: 1025px) {
    flex: 0 0 calc(25% - ((20 / 4) * 3px));
    max-width: calc(25% - ((20 / 4) * 3px));
  }

  @media (min-width: 1441px) {
    flex: 0 0 calc(20% - ((20 / 5) * 4px));
    max-width: calc(20% - ((20 / 5) * 4px));
  }
`;

const BaseContainer = styled.div`
  display: flex;
  padding: 0;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
`;

const NameContainer = styled(BaseContainer)`
  flex-direction: row;
  align-items: center;
  gap: 4px;
  order: 0;
  &.emptyDashboardPlaceholderField {
    width: 109px;
    height: 20px;
  }
`;

const IDContainer = styled.div`
  padding: 0 4px 0 4px;
`;

const RowContainer = styled(BaseContainer)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ContentContainer = styled(BaseContainer)`
  flex-direction: column;
  align-items: flex-start;
  order: 0;
  min-height: 34px;
`;

const LabWorksContentContainer = styled(ContentContainer)`
  flex-direction: column;
  gap: 3px;
`;

const FirstRowContainer = styled(RowContainer)`
  gap: 4px;
  order: 0;
`;

const SecondRowContainer = styled(RowContainer)`
  gap: 4px;
  order: 1;
  justify-content: normal;
`;

const PatientNameConatiner = styled.div`
  &.emptyDashboardPlaceholderField {
    margin-top: 2px;
    min-width: 45px;
    min-height: 12px;
  }
`;

const SeeMoreText = styled(Text)`
  line-height: 16px;
  &.emptyDashboardPlaceholderField {
    min-width: 80px;
    min-height: 16px;
  }
`;

const ProgressContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1px;
  width: 100%;
`;

const ProgressIcon = styled.span`
  border-radius: 1.5px;
  flex: 1;
  background: var(--dts_light_grey);
  border: 1.5px solid var(--dts_light_grey);
  &.emptyDashboardPlaceholderField {
    border: 1.5px solid var(--dts_withe_background);
    background: var(--dts_withe_background);
  }
`;
