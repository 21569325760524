import { WorkTypeRepository } from '../../domain/worktype/WorkTypeRepository';

type GetWorkTypesUseCase = Pick<WorkTypeRepository, 'getWorkTypes' | 'workTypes'>;

const getWorkTypesUseCase = (repo: GetWorkTypesUseCase) => {
  repo.getWorkTypes();
};

const getSortWorkTypesUseCase = (repo: GetWorkTypesUseCase) => {
  if (repo.workTypes === null) return [];
  return repo.workTypes.slice().sort((a, b) => (a.order > b.order ? 1 : -1));
};

export { getWorkTypesUseCase, getSortWorkTypesUseCase };
