export class DashboardUiModel {
  id?: number;
  clientName?: string;
  patientName?: string;
  status: number;
  labWork?: DashboardLabWorkUiModel;
  labWorksSize?: number;
  expireTime?: { time: string | undefined; expire: boolean };

  constructor(
    status: number,
    id?: number,
    client?: string,
    patient?: string,
    laboratoryWork?: DashboardLabWorkUiModel,
    labWorksSize?: number,
    expireTime?: { time: string | undefined; expire: boolean },
  ) {
    this.id = id;
    this.clientName = client;
    this.patientName = patient;
    this.status = status;
    this.labWork = laboratoryWork;
    this.labWorksSize = labWorksSize;
    this.expireTime = expireTime;
  }
}

export class DashboardLabWorkUiModel {
  labWorkName?: string;
  labWorkSubcategory?: string;
  currentlyPhaseName?: string;
  currentlyPhaseStatus?: string;
  currentlyAvatar?: string;
  currentlyAvatarColor?: string;
  previousPhaseStatus?: string;
  allPhaseStatus?: { index: number; status: string; icon: boolean; current: boolean }[];
  phaseEdgeRight?: boolean;
  phaseEdgeLeft?: boolean;

  constructor(
    labWorkName?: string,
    labWorkSubcategory?: string,
    currentlyPhaseName?: string,
    currentlyPhaseStatus?: string,
    currentlyAvatar?: string,
    currentlyAvatarColor?: string,
    previousPhaseStatus?: string,
    allPhaseStatus?: { index: number; status: string; icon: boolean; current: boolean }[],
    phaseEdgeRight?: boolean,
    phaseEdgeLeft?: boolean,
  ) {
    this.labWorkName = labWorkName;
    this.labWorkSubcategory = labWorkSubcategory;
    this.currentlyPhaseName = currentlyPhaseName;
    this.currentlyPhaseStatus = currentlyPhaseStatus;
    this.currentlyAvatar = currentlyAvatar;
    this.currentlyAvatarColor = currentlyAvatarColor;
    this.previousPhaseStatus = previousPhaseStatus;
    this.allPhaseStatus = allPhaseStatus;
    this.phaseEdgeRight = phaseEdgeRight;
    this.phaseEdgeLeft = phaseEdgeLeft;
  }
}
