import React, { useState } from 'react';
import { BaseDialog } from '../../../../components/generic/BaseDialog';
import PhaseTypeSelector, { Type } from './PhaseTypeSelector';
import { Text, TextType } from '../../../../components/dsm/Text';
import { Dropdown, DropdownProps } from '../../../../components/generic/Dropdown';
import { usePhaseRepositoryImplementation } from '../../../../../data/phase/phasesRepositoryImplementation';
import styled from 'styled-components';
import { Button } from '../../../../components/dsm/buttons/Button';
import { PhaseUiModel, PhaseUiModelPhase } from '../../../../ui-model/PhaseUiModel';
import { ReactComponent as CalendarIcon } from '../../../../../icons/calendar.svg';
import { Template } from '../../../../../domain/worktype/templates/Template';
import { useTemplateRepositoryImplementation } from '../../../../../data/worktype/templates/TemplateRepositoryImplementation';
import { useTemplatesViewModel } from '../../../../settings/worktypes/worktypeitem/TemplatesViewModel';
import { TemplateUiModel } from '../../../../ui-model/worktype/TemplateUiModel';
import { useCaseOverViewViewModel } from '../../../CaseOverViewViewModel';
import { useContactsRepositoryImplementation } from '../../../../../data/user-management/UsersRepositoryImplementation';
import { featuresRepositoryImplementation } from '../../../../../data/features/FeaturesRepositoryImplementation';
import useTranslate from '../../../../translations/useTranslate';
import { LANGUAGE_KEYS } from '../../../../translations/languageKeys';
import DateTimePickerModal from '../../../../components/dsm/DateTimePickerModal';
import {
  getFormattedHours,
  getFormattedMinutes,
  mapDay,
  mapMonth,
} from '../../../../components/dsm/DateTimePicker';
import { DropdownWithInput } from '../../../../components/generic/DropdownWithInput';

type AddPhaseDialogProps = {
  worktypeId: number;
  onClose: () => void;
  onAddPhase: (phase: PhaseUiModel) => void;
  onAddDate: (date: Date) => void;
  onAddTemplate: (phase: Template) => void;
};

const AddPhaseDialog = (props: AddPhaseDialogProps) => {
  const translate = useTranslate();
  const { worktypeId, onClose, onAddPhase, onAddDate, onAddTemplate } = props;

  const repoTemplates = useTemplateRepositoryImplementation();
  const phaseRepo = usePhaseRepositoryImplementation();
  const contactRepo = useContactsRepositoryImplementation();
  const featureRepo = featuresRepositoryImplementation();

  const { phases } = useCaseOverViewViewModel(contactRepo, featureRepo, phaseRepo);
  const { getSortTemplatesByWorkTypeId } = useTemplatesViewModel(repoTemplates);

  const templates: TemplateUiModel[] = getSortTemplatesByWorkTypeId(Number(worktypeId));

  const [selectedPhaseId, setSelectedPhaseId] = useState<PhaseUiModelPhase['id']>();
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedTemplateId, setSelectedTemplateId] = useState<PhaseUiModelPhase['id']>();
  const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false);
  const [selectedType, setSelectedType] = useState<Type>('new-phase');

  const handleOnCreate = () => {
    selectedType === 'new-phase' &&
      onAddPhase(
        phases.find((phase) => phase.kind != 'Date' && phase.id === selectedPhaseId) || phases[0],
      );
    selectedType === 'date-and-time' && onAddDate(selectedDate);
    selectedType === 'choose-from-template' &&
      onAddTemplate(
        templates?.find((template) => template.id === selectedTemplateId) || templates?.slice[0],
      );
  };

  const renderNewPhaseType = () => {
    return (
      <div>
        <Text type={TextType.CAPTION_REGULAR_2} style={{ marginBottom: '2px' }}>
          {translate(LANGUAGE_KEYS.PHASE_NAME)}
        </Text>
        <DropdownWithInput
          values={phases.reduce((result: DropdownProps['values'], phase) => {
            phase.kind != 'Date' &&
              result.push({
                key: phase.id,
                label: phase.name,
                selected: phase.id === selectedPhaseId,
              });
            return result;
          }, [])}
          onSelect={(changedValueKey) => {
            setSelectedPhaseId(changedValueKey);
          }}
          hasSuccessIcon={false}
        />
      </div>
    );
  };

  const renderNewTemplateType = () => {
    return (
      <div>
        <Text type={TextType.CAPTION_REGULAR_2} style={{ marginBottom: '2px' }}>
          {translate(LANGUAGE_KEYS.TEMPLATE_NAME)}
        </Text>
        <Dropdown
          values={templates!.map((template) => {
            return {
              key: template.id,
              label: template.name,
              selected: template.id === selectedTemplateId,
            };
          })}
          onSelect={(changedValueKey) => setSelectedTemplateId(changedValueKey)}
          hasSuccessIcon={false}
        />
      </div>
    );
  };

  const renderDateAndTimeType = () => {
    return (
      <div>
        <Text type={TextType.CAPTION_REGULAR_2} style={{ marginBottom: '2px' }}>
          {translate(LANGUAGE_KEYS.SET_DUE_DATE_AND_TIME)}
        </Text>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '4px',
          }}
        >
          <DateOrTimeInput onClick={() => setIsDatePickerOpen(true)}>
            <CalendarIcon />
            <InputDateSeparator />
            <Text type={TextType.BODY_REGULAR}>
              {mapDay(selectedDate.getDate())}{' '}
              {translate(mapMonth(selectedDate.getMonth())).substring(0, 3)}{' '}
              {selectedDate.getFullYear()}, {getFormattedHours(selectedDate)}:
              {getFormattedMinutes(selectedDate)}
            </Text>
          </DateOrTimeInput>
        </div>
        {isDatePickerOpen && (
          <DateTimePickerModal
            date={selectedDate}
            isTime={true}
            onCancel={() => setIsDatePickerOpen(false)}
            onSave={(date) => {
              setSelectedDate(date);
              setIsDatePickerOpen(false);
            }}
          />
        )}
      </div>
    );
  };

  return phases && phases.length > 0 ? (
    <BaseDialog
      isOpen={true}
      title={translate(LANGUAGE_KEYS.ADD_NEW_ITEM)}
      onClose={onClose}
      containerStyle={{ width: '408px' }}
      header={true}
      isScroll={false}
    >
      <PhaseTypeSelector selectedType={selectedType} setSelectedType={setSelectedType} />
      {selectedType === 'new-phase' && renderNewPhaseType()}
      {selectedType === 'date-and-time' && renderDateAndTimeType()}
      {selectedType === 'choose-from-template' && renderNewTemplateType()}
      <Row>
        <Button secondary='secondary' onClick={onClose}>
          {translate(LANGUAGE_KEYS.CANCEL)}
        </Button>
        <Button onClick={handleOnCreate}>{translate(LANGUAGE_KEYS.CREATE)}</Button>
      </Row>
    </BaseDialog>
  ) : (
    <React.Fragment />
  );
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  gap: 16px;
`;

const DateOrTimeInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  gap: 8px;
  padding: 8px;
  height: 40px;
  box-sizing: border-box;
  background-color: var(--dts_withe_background);
`;

const InputDateSeparator = styled.div`
  display: block;
  width: 1px;
  height: 16px;
  background-color: var(--dts_black);

  svg {
    width: 16px;
    height: 16px;
  }
`;

export default AddPhaseDialog;
