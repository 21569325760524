import { DashboardCase, DashboardCasesData } from '../../domain/dashboard/DashboardCase';
import { BASE_URL } from '../Server';
import { fetchWithAuthAndCompanyId } from '../utils/fetchWithAuthAndCompanyId';

const getDashboardCases = async (
  pageNumber: number,
  itemNumber: number,
): Promise<DashboardCasesData> => {
  try {
    const response = await fetchWithAuthAndCompanyId(
      `${BASE_URL}/dashBoardCases/${pageNumber}/${itemNumber}`,
      {
        method: 'GET',
      },
    );
    if (response.ok) {
      const data: Array<DashboardCase> = await response.json();
      return { code: 200, message: 'Success', dashboardCases: data };
    } else {
      return { code: response.status, message: 'Faild', dashboardCases: [] };
    }
  } catch (error) {
    return { code: error.status, message: error, dashboardCases: [] };
  }
};

export { getDashboardCases };
