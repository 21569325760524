import React from 'react';
// Repositories
import { AuthRepository } from '../domain/authenticationFlow/AuthRepository';
import { LobbyRepository } from '../domain/lobby/LobbyRepository';
import { CountiesRepository } from '../domain/county/CountyRepository';
import { ExtensionRepository } from '../domain/worktype/extension/ExtensionRepository';
import { PhaseRepository } from '../domain/PhaseRepository';
import { TemplateRepository } from '../domain/worktype/templates/TemplateRepository';
import { WorkTypeRepository } from '../domain/worktype/WorkTypeRepository';
import { CompaniesRepository, ContactsRepository } from '../domain/user-management/UsersRepository';
import { BillingRepository } from '../domain/billing/BillingRepository';
import { InvoiceRepository } from '../domain/invoice/InvoiceRepository';
import { E_facturaRepository } from '../domain/e-factura/E-facturaRepository';

// Usecases
import {
  setLaboratoryUseCases,
  startSetLaboratoryUseCase,
} from '../useCases/authenticationFlow/laboratoryUseCase';
import { getLobbyCompaniesUseCase } from '../useCases/lobby/getLobbyCompaniesUseCase';
import { getCounties } from '../useCases/getCountiesUseCase';
import { getExtensionsUseCase } from '../useCases/worktype/extension/getExtensionsUseCases';
import { getPhasesUseCase } from '../useCases/worktype/phases/getPhasesUseCase';
import { getTemplatesUseCases } from '../useCases/worktype/templates/getTemplatesUseCases';
import { getWorkTypesUseCase } from '../useCases/worktype/getWorkTypesUseCase';
import { getContactsUseCase } from '../useCases/user-management/getContactsUseCase';
import {
  getCompaniesUseCase,
  getMyCompanyDataUseCase,
} from '../useCases/user-management/getCompaniesUseCase';
import { getCompanySubscriptionUseCase } from '../useCases/billing/getCompanySubscriptionUseCase';
import { getInvoiceUseCase } from '../useCases/invoice/getInvoiceUseCase';
import { refreshETokenUseCase } from '../useCases/e-factura/newAuthUseCase';
import { MenuType } from '../presentation/ui-model/menu/MenuUImodel';
import { isEnabledRouteByPathName } from '../useCases/menu/getMenuUseCase';
import { FeaturesRepository } from '../domain/features/FeaturesRepository';

function useRouteViewModel(
  authRepo: AuthRepository,
  lobbyRepo: LobbyRepository,
  countiesRepo: CountiesRepository,

  extensionRepo: ExtensionRepository,
  phaseRepo: PhaseRepository,
  templateRepo: TemplateRepository,
  workTypeRepo: WorkTypeRepository,
  contactsRepo: ContactsRepository,
  companiesRepo: CompaniesRepository,
  billingRepo: BillingRepository,
  invoiceRepo: InvoiceRepository,
  e_facturaRepo: E_facturaRepository,

  featureRepo: FeaturesRepository,
) {
  const setLaboratory = (companyId: number | null) => {
    if (lobbyRepo.lobbyCompanies !== null) {
      const currentCompany = lobbyRepo.lobbyCompanies
        .filter((comp) => comp.companyId === Number(companyId))
        .pop();
      if (currentCompany !== undefined) {
        startSetLaboratoryUseCase(authRepo);
        return setLaboratoryUseCases(authRepo, currentCompany);
      } else {
        return setLaboratoryUseCases(authRepo, null);
      }
    }
    return false;
  };

  const loadDataForApp = React.useCallback(function (userId: number | null) {
    getLobbyCompaniesUseCase({ getLobbyCompanies: lobbyRepo.getLobbyCompanies }, userId);
    getCounties({ counties: countiesRepo.counties, getCounties: countiesRepo.getCounties });
  }, []);

  const loadDataForLaboratory = React.useCallback(function () {
    getExtensionsUseCase({ getExtensions: extensionRepo.getExtensions });
    getPhasesUseCase({ getPhases: phaseRepo.getPhases, phases: phaseRepo.phases });
    getTemplatesUseCases({ getTemplates: templateRepo.getTemplates });
    getWorkTypesUseCase({
      getWorkTypes: workTypeRepo.getWorkTypes,
      workTypes: workTypeRepo.workTypes,
    });
    getContactsUseCase({ getContacts: contactsRepo.getContacts, contacts: contactsRepo.contacts });
    getCompaniesUseCase({
      getCompanies: companiesRepo.getCompanies,
      companies: companiesRepo.companies,
      getMyCompanyData: companiesRepo.getMyCompanyData,
    });
    getCompanySubscriptionUseCase({ getCompanySubscription: billingRepo.getCompanySubscription });
    getInvoiceUseCase({
      invoices: invoiceRepo.invoices,
      getInvoices: invoiceRepo.getInvoices,
      getInvoiceById: invoiceRepo.getInvoiceById,
    });
    refreshETokenUseCase(e_facturaRepo);
    getMyCompanyDataUseCase({
      getCompanies: companiesRepo.getCompanies,
      companies: companiesRepo.companies,
      getMyCompanyData: companiesRepo.getMyCompanyData,
    });
  }, []);

  const isEnabledRoute = (
    userRole: number,
    pathname: string,
    deviceType: MenuType,
    validSubscription: boolean,
  ): boolean => {
    return isEnabledRouteByPathName(featureRepo, userRole, pathname, deviceType, validSubscription);
  };

  return {
    setLaboratory,
    loadDataForApp,
    loadDataForLaboratory,
    isEnabledRoute,
  };
}

export default useRouteViewModel;
