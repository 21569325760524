import React, { HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';
import { DeviceSizeProps } from '../../DeviceInformations';
import { useDeviceParameters } from '../../hooks/useDeviceParameters';

type ContentLayoutProps = {
  children: ReactNode;
} & HTMLAttributes<HTMLDivElement>;

const ContentLayout = (props: ContentLayoutProps) => {
  const { mobileSmallSize, mobileSize, tabletLargeSize, tabletSmallSize, desktopSize } =
    useDeviceParameters();

  return (
    <ContentLayoutStyled
      mobile_small={mobileSmallSize}
      mobile={mobileSize}
      tablet_small={tabletSmallSize}
      tablet_large={tabletLargeSize}
      desktop={desktopSize}
    >
      {props.children}
    </ContentLayoutStyled>
  );
};

const ContentLayoutStyled = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
      max-width: 1648px;
      margin: auto;
      padding: 0px 36px 36px 36px;
      height: calc(100% - 119px);
      
      //Desktop
      @media (min-width: ${desktop}px) {
      }
      // Tablet large
      @media (max-width: ${tablet_large}px) {
      }
      // Tablet small
      @media (max-width: ${tablet_small}px) {
      }
      // Mobile
      @media (max-width: ${mobile}px) {
      }
      //Mobile small 
      @media (max-width: ${mobile_small}px) {
        padding: 0px 16px 16px 16px;
        height: calc(100% - 103px);
      }
  `,
);
export default ContentLayout;
