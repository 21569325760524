import React from 'react';
import styled from 'styled-components';
import { ModalStyles } from '../generic/BaseDialog';
import Modal from 'react-modal';
import DateTimePicker from './DateTimePicker';
import { Text, TextType } from '../dsm/Text';
import { ReactComponent as CloseIcon } from '../../../icons/close.svg';
import { LANGUAGE_KEYS } from '../../translations/languageKeys';
import useTranslate from '../../translations/useTranslate';

type DatePickerProps = {
  date?: Date;
  isTime: boolean;
  onCancel: () => void;
  onSave: (date: Date) => void;
};

const DateTimePickerModal = (props: DatePickerProps) => {
  const { date = new Date(), isTime, onCancel, onSave } = props;
  const translate = useTranslate();

  return (
    <Modal
      isOpen={true}
      onRequestClose={() => onCancel()}
      shouldCloseOnEsc={true}
      style={{ ...ModalStyles, content: { ...ModalStyles.content, flexDirection: 'column' } }}
      ariaHideApp={false}
    >
      <Row>
        <Text type={TextType.TITLE_BOLD_4} ellipsis={true}>
          {translate(LANGUAGE_KEYS.SELECT_DATE)}
        </Text>
        <CloseIconStyled onClick={onCancel} />
      </Row>
      <DateTimePickerContainer>
        <DateTimePicker isTime={isTime} date={date} onCancel={onCancel} onSave={onSave} />
      </DateTimePickerContainer>
    </Modal>
  );
};

const DateTimePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  user-select: none;
  width: calc(506px - 2 * 36px);
  padding: 36px;
  overflow-y: auto;
`;

const Row = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  overflow: hidden;
  padding: 36px 36px 0px 36px;
`;

const CloseIconStyled = styled(CloseIcon)`
  width: 14px;
  height: 14px;
  cursor: pointer;
  path {
    fill: var(--dts_black);
  }
  :hover {
    path {
      fill: var(--dts_pressed_blue);
    }
  }
`;

export default DateTimePickerModal;
